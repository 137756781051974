.homepage-header {
  position: relative;
  display: flex;
  padding: $spacing-m 0;
  min-height: 300px;
  background: $background-lightest;

  //to prevent white space on scrollbounce
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -$navigation-height--desk;
    height: $navigation-height--desk;
    width: 100%;
    background: $background-lightest;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .title {
    hyphens: auto;

    @include tablet-only {
      font-size: 32px;
    }

    @include tablet {
      margin: 0 0 $spacing-m;
      text-align: center;
    }
  }

  .description {
    margin: 0 0 $spacing-m;
  }

  form {
    width: 100%;
    max-width: 618px;

    input {
      padding: 0 $spacing-m;
    }
  }

  &.has-image {
    display: none;

    @include tablet-landscape {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .title {
        text-align: left;

        @include tablet {
          font-size: 2.5em;
        }

        @include widescreen {
          font-size: 3em;
        }
      }

      .container {
        position: absolute;
        top: 0;
        left: 0;

        align-items: flex-start;
        height: 100%;
        width: 50vw;

        @include container-min {
          left: calc(50vw - #{calc($grid-width / 2)} - #{$grid-gap});
          width: #{calc($grid-width / 2)};
        }
      }

      .image {
        max-width: 50vw;
        line-height: 0;
      }
    }
  }
}
