// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Layout settings
$grid-width: 1260px;
$grid-gap: 24px;
$grid-gap--mobile: 12px;
$grid-column-count: 12;
$grid-column-count--mobile: 4;
$article-width: 708px;
$container-padding: 24px;
$container-padding-mobile: 16px;

// Fonts
$text-font-stack: Helvetica, sans-serif;
$title-font-stack: Helvetica, sans-serif;
$font-size-small: 0.75em;
$font-correction-button--pt: 0 !default;
$font-correction-button--pb: 0 !default;

// Style settings
$box-shadow: 0 0 15px rgba(#2f4e5f, 0.2);
$box-shadow-inset: inset 0 0 5px rgba(#2f4e5f, 0.25);
$border-radius: 3px;
$border-radius--small: 2px;

// Other settings
$general-transition-speed: 0.3s linear;
$form-transition-speed: 0.15s linear;
$modal-transition-speed: 0.3s ease-out;

//Spacing
$spacing-xxs: 6px;
$spacing-xs: 8px;
$spacing-s: 12px;
$spacing-ms: 16px;
$spacing-m: 24px;
$spacing-ml: 32px;
$spacing-l: 40px;
$spacing-xl: 48px;
$spacing-xxl: 56px;
$spacing-xxxl: 80px;

// Navigation
$navigation-height--desk: 72px;
$navigation-height--mobile: 64px;
$navigation-search-height: 72px;
$navigation-border-bottom: 1px solid #000;
