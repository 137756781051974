/*
Checkout login pane

Markup:
<div class="login-pane">
  <div class="login-pane__help-text">
    <h2 class="title-m--alt">Log into your account</h2>
    <p>
      Use your account to login and complete your order
    </p>
  </div>
  <button class="button--primary" @click.prevent="login">
    Inloggen
  </button>
</div>

Style guide: components.login-pane
*/

.login-pane-container {
  align-items: center;
  display: flex;
  justify-content: center;

  min-height: calc(100vh - #{$header-size} - #{$footer-size});

  margin: 1.5rem 0;
}

.login-pane {
  min-height: 360px;
  margin: 0 auto;
  max-width: calc($grid-width / 3);
  padding-bottom: $spacing-l;

  button:not(.is-full-width) {
    margin: 0 auto;
    max-width: 295px;
    width: 100%;
  }
}

.login-pane__help-text {
  padding-bottom: 2.25em;
  padding-top: 3.75em;
  text-align: center;

  h2 {
    font-size: 20px;
    margin-bottom: $spacing-s;
  }

  @include tablet {
    h2 {
      font-size: 24px;
      margin-bottom: $spacing-m;
    }

    p {
      font-size: 20px;
    }
  }
}
