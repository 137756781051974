.block--multi-column {
  .block__content {
    grid-column: 3 / -3;
    display: flex;

    @include mobile-only {
      flex-direction: column;
    }
  }

  .column {
    @include mobile-only {
      margin: 0 0 $spacing-m;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include tablet {
      flex: 1 1 0;
      margin: 0 #{calc($grid-gap / 2)};

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
