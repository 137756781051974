.article-block--freeform {
  .article-block__content {
    grid-column: 5 / -5;
  }

  .title-m--alt,
  .description {
    margin-bottom: $spacing-s;
  }

  .form {
    margin-top: $spacing-s;

    label {
      display: flex;
    }

    .checkbox {
      margin-right: 12px;
    }

    .button--light {
      background: $color-primary;
      color: $color-primary-negative;
      width: 100%;
    }
  }
}
