.block {
  position: relative;
  padding: $spacing-l 0;

  .title {
    padding: 0 0 $spacing-ms;
  }

  &.background--light {
    background: $background-light;
  }
}
