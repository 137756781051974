.select-list {
  & + .select-list {
    margin-top: $spacing-l;
  }

  .title {
    margin-bottom: $spacing-m;
    font-weight: bold;
  }

  input {
    margin: 0 0 8px;
  }

  .show-more {
    margin: 8px 0 0;
  }

  .no-search-result {
    font-size: 0.875em;
  }

  .option {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin: $spacing-xs 0 0;
    font-size: 14px;

    .checkbox {
      margin: 0 8px 0 0;
    }

    &.selected {
      font-weight: 700;
    }

    &:not(.available) {
      opacity: 0.6;
    }
  }
}
