.category-page {
  .category:not(.active) {
    .image {
      opacity: 0.5;
    }
    .title-xs {
      opacity: 0.3;
    }
  }
}

.category-slider-wrapper {
  padding: $spacing-l 0;
  background: $background-light;

  @include mobile-only {
    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.filter-page {
  @include tablet {
    padding: 40px 0;
  }

  @include mobile-only {
    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .category-filters {
    .search-query {
      margin: 0 0 $spacing-m;
    }
  }

  .search-query {
    margin: $spacing-l 0 0;

    @include tablet {
      grid-column: 5 / 14;
      margin: 0 0 $spacing-l;
    }

    span {
      font-weight: 500;
    }
  }

  .load-more {
    margin-top: $spacing-m;
    grid-column: 6 / 12;
    outline: none;

    @include mobile-only {
      margin: $spacing-m;
    }
  }
}
