.navigation-search-mobile {
  padding: $spacing-s;
  background: $background-dark;

  .icon {
    color: $color-white;
  }

  input {
    background: rgba(255, 255, 255, 0.2);
    -webkit-appearance: none;
  }
}
