.search-page {
  @include tablet {
    padding: 40px 0;
  }

  .search-results {
    grid-row: 2 / 3;
    grid-column: 2 / -2;

    .product-list {
      grid-row: 2 / 3;

      @include tablet {
        grid-template-columns: 1fr 1fr 1fr;
      }

      @include desktop {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }

    .product-list--page {
      grid-template-columns: 1fr;
      grid-gap: 0;
    }

    &:not(.active) {
      display: none;
    }

    .tile:not(.page-tile) {
      @include tablet-only {
        &:nth-child(6n + 1),
        &:nth-child(6n + 5) {
          grid-column: span 2;
          grid-row: span 2;

          .title-m {
            font-size: 2.5em;
          }
        }
      }

      @include desktop {
        &:nth-child(11n + 1) {
          grid-column: span 2;
          grid-row: span 2;

          .title-m {
            font-size: 2.5em;
          }
        }
      }
    }
  }

  .search-query {
    margin: $spacing-l 0 0;

    @include tablet {
      grid-row: 1 / 2;
      grid-column: 2 / -2;
      margin: 0 0 $spacing-m;
    }

    span {
      font-weight: 500;
    }
  }

  .search-nav {
    grid-row: 1 / 2;
    grid-column: 2 / -2;
  }

  .load-more {
    margin: $spacing-m auto 0;
    width: 350px;

    @include mobile-only {
      margin: $spacing-m 0;
      width: 100%;
    }
  }
}
