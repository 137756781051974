$category-width-mobile: 200px;
$category-width-desk: 280px;

.category-slider {
  position: relative;
  grid-column: 1 / -1;
  grid-row: 2 / 3;

  .slider {
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    display: flex;

    -ms-overflow-style: none; /* Hide scrollbar: IE and Edge */
    scrollbar-width: none; /* Hide scrollbar: Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .spacer {
    @include container-min {
      scroll-snap-align: start;
      display: block;
      flex: 0 0 calc(50vw - #{calc($grid-width / 2) + $grid-gap});
    }
  }

  .category {
    scroll-snap-align: start;
    flex: 0 0 $category-width-mobile;
    padding: 0 0 0 $container-padding-mobile;

    &:last-child {
      @include mobile-only {
        flex: 0 0 #{$category-width-mobile + $container-padding-mobile};
        padding-right: $container-padding-mobile;
      }

      @include tablet {
        flex: 0 0 #{$category-width-desk + $grid-gap};
        padding-right: $grid-gap;
      }
    }

    @include tablet {
      flex: 0 0 $category-width-desk;
      padding: 0 0 0 $grid-gap;
    }
  }

  .category__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .image {
    position: relative;
    margin: 0 0 $spacing-s;
    padding: 56% 0 0;
    width: 100%;
    // height: 120px;

    // @include tablet {
    //   height: 160px;
    // }

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .button {
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    padding: 0;
    min-height: $spacing-ml;
    min-width: $spacing-ml;
    border-radius: 100%;
    background: $color-white;
    color: $text-color;
    outline: none;

    .fas {
      margin: 0;
    }

    @include mobile-only {
      display: none;
    }
  }

  .button--prev {
    left: $spacing-ml;

    @include mobile-only {
      left: $container-padding-mobile;
    }
  }

  .button--next {
    right: $spacing-m;

    @include mobile-only {
      right: $container-padding-mobile;
    }
  }
}
