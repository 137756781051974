.algolia-search {
  grid-auto-rows: auto;

  input {
    width: 100%;
  }
}

.algolia-search__search {
  grid-column: 5 / -5;
  grid-row: 1 / 2;

  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;

  @include mobile-only {
    display: none;
  }

  .searchbox {
    width: 100%;
  }
}

.algolia-search__sidebar {
  grid-column: 2 / 5;

  .filters-list {
    margin-bottom: 32px;
  }

  .ais-ClearRefinements-button,
  .filter-button {
    -webkit-appearance: none;
    display: inline-block;
    border: none;
    margin: 0 0 0 8px;
    padding: 0;
    color: $color-primary;
    background: none;
    font-size: 12px;
  }

  .filter-button {
    margin: 0 0 0 auto;

    @include tablet {
      display: none;
    }
  }

  @include mobile-only {
    .ais-RefinementList {
      padding: 0 0 16px;

      &.list--closed .ais-RefinementList-list {
        display: none;
      }
    }
  }
}

.algolia-search__main {
  grid-column: 5 / -2;
}

.algolia-search__results {
  margin: 0 0 11px;
}

// Search list
.search-list {
  list-style-type: none;
  padding: 16px 0 0;
  border-top: 1px solid #f2f2f2;
}

body:not(.category-page) {
  .algolia-search__main {
    grid-column: 5 / -5;
  }

  @include tablet {
    .ais-RefinementList-list {
      display: flex;
    }

    .ais-RefinementList-item {
      flex: 1;
    }
  }

  .algolia-search__sidebar {
    grid-column: 5 / -5;

    @include tablet {
      padding: 0 0 42px;
    }

    .filters-title {
      display: none;
    }

    .filters-header {
      display: none;
    }
  }
}
