/*
Tally Counter

Markup:
<div class="tally-counter">
  <button class="tally-counter__btn">
    <i class="fal fa-minus"></i>
  </button>
  <input
    class="tally-counter__input"
    type="number"
    value="1"
  />
  <button class="tally-counter__btn">
    <i class="fal fa-plus"></i>
  </button>
</div>

Style guide: components.elements.tally-counter
*/

.tally-counter {
  align-items: center;
  background: $background-dark;
  border-radius: $border-radius--small;
  display: flex;
  height: 42px;
  width: 125px;
}

.tally-counter__input {
  background: $background-dim;
  border: none;
  border-radius: $border-radius--small;
  color: $color-white;
  display: inline-block;
  height: 28px;
  margin: $spacing-xxs 0 $spacing-xs;
  min-height: 0;
  text-align: center;
  width: 48px;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}

.tally-counter__btn {
  color: $color-white;
  cursor: pointer;
  width: 38px;
  transition: transform $form-transition-speed;

  @include on-event {
    outline: none;
    transform: translateY(1px);
  }
}
