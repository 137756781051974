.checkout-page-footer {
  margin-top: auto;
  background: $background-dark;
  color: $color-white;
  padding-top: $spacing-m;

  a:not(.button--light) {
    color: $color-white;
  }

  em {
    color: $color-accent-hard;
    font-style: normal;
  }

  @include tablet {
    padding-bottom: $spacing-s;
  }
}

.checkout-page-footer__support {
  grid-column: 2 / 5;
  padding-bottom: $spacing-xxl;
  padding-top: $spacing-ms;
}

.checkout-page-footer__support__cta {
  margin-top: 1.5em;
  max-width: 320px;
}

.checkout-page-footer__links {
  grid-column: -4 / -2;

  .fas {
    color: $color-accent-hard;
    margin-right: 11px;
  }
}

.checkout-page-footer__links__item {
  @extend small;
  padding: $spacing-ms 0;

  & ~ .checkout-page-footer__links__item {
    border-top: 1px solid $color-accent-hard;

    @include tablet {
      border-top: none;
    }
  }
}
