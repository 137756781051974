/*
Order summary

Markup:
<div class="order-summary">
  <div class="order-summary__content">
    <p>
      You selected:<br />
      <strong>Product Name</strong>
    </p>
  </div>
  <div class="order-summary__image">
    <img src="https://via.placeholder.com/640x480/09f/fff/?text=img" />
  </div>
</div>

Style guide: components.checkout.order-summary
*/

.order-summary {
  display: grid;
  grid-template-columns: 128px auto;
  grid-gap: 30px;

  @include desktop {
    display: block;
  }
}

.order-summary__content {
  @extend small;
  grid-column: 2 / 3;
  padding-top: 32px;

  strong {
    font-size: 15px;
  }

  @include desktop {
    padding-top: 0;
    padding-bottom: 14px;
  }
}

.order-summary__image {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 100%;

  img {
    display: block;
    object-fit: cover;
  }

  @include tablet-landscape {
    width: 87.5%;
  }

  @include widescreen {
    width: 100%;
  }
}
