.dev-label {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
  padding: 4px 8px;
  background: #000;
  color: #fff;
}

.sitemap-content {
  grid-column: 2 / 14;
}

.sitemap {
  font-size: 14px;
  width: 100%;
}
.sitemap-section,
.sitemap-fields {
  background: #f2f2f2;
}

.sitemap-fields {
  opacity: 0.5;
  font-size: 12px;
  cursor: pointer;
}

.sitemap-fields small {
  opacity: 0.5;
}

.show-on-hover {
  display: none;
}

.sitemap-fields:hover .show-on-hover {
  display: block;
}

.sitemap-section strong {
  font-size: 16px;
}

.sitemap-section span {
  opacity: 0.3;
  font-size: 12px;
}

.sitemap-section span:last-child {
  float: right;
  font-size: 12px;
}

.sitemap-section span.icon {
  float: right;
  margin: 0 0 0 8px;
  opacity: 1;
  font-size: 18px;
}

.spacer td {
  padding: 10px 0;
  border: none;
}

.blokje,
.container-blokje {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2f2f2;
  grid-row: 2 / 3;
  padding: 4px 0;
  margin-bottom: 8px;
  text-align: center;
}

.container-blokje {
  margin-bottom: 60px;
}

@for $i from 1 through 5 {
  .row--#{$i} {
    grid-row: #{$i + 1} / #{$i + 2};
  }
}

.row--1 {
  @for $j from 0 through 13 {
    &.blokje--#{$j} {
      grid-column: #{$j + 1} / #{$j + 2};
    }
  }
}

.row--2.blokje--0 {
  grid-column: 2 / 8;
}
.row--2.blokje--1 {
  grid-column: 8 / 14;
}

.row--3.blokje--0 {
  grid-column: 2 / 6;
}
.row--3.blokje--1 {
  grid-column: 6 / 8;
}
.row--3.blokje--2 {
  grid-column: 8 / 15;
}

.row--4.blokje--0 {
  grid-column: 2 / 5;
}
.row--4.blokje--1 {
  grid-column: 5 / 8;
}
.row--4.blokje--2 {
  grid-column: 8 / 11;
}
.row--4.blokje--3 {
  grid-column: 11 / 14;
}

.row--5.blokje--0 {
  grid-column: 2 / -2;
}

//Dev values
.dev-grid-gap:after {
  content: "#{ $grid-gap }";
}

$vars: (
  grid-width: $grid-width,
  grid-gap: $grid-gap,
  container-padding: $container-padding,
  container-padding-mobile: $container-padding-mobile,
  box-shadow: $box-shadow,
  border-radius: $border-radius
);

@each $var in map-keys($vars) {
  .dev-#{$var}:after {
    content: "#{map-get($vars, $var)}";
    margin: 0 0 0 8px;
    font-weight: 700;
  }
}

.dev-intro {
  margin: 4px 0 32px;
}

.dev-table {
  border-collapse: collapse;
  border-top: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;

  td,
  th {
    text-align: left;
    border-left: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    padding: 8px 16px;
  }

  th {
    background: #ccc;
  }
}

.dev-color__circle {
  height: 20px;
  width: 20px;
  border-radius: 100%;
  border: 1px solid #f2f2f2;
}

.sitemap__buttons {
  display: flex;
  margin-top: 32px;
  margin-bottom: 32px;

  button {
    cursor: pointer;
    margin: 0 12px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    padding: 0 12px;
    background: #f2f2f2;
    border-radius: 3px;
    outline: none;

    &.active {
      background: #666;
      color: #fff;
    }
  }
}
