/*
Ticket table

Markup:
<div class="ticket-table">
  <div class="ticket-table__header">
    <div class="ticket-table__title">
      tickets
    </div>
    <div class="ticket-table__title">
      prijs
    </div>
    <div class="ticket-table__title">
      aantal
    </div>
    <div class="ticket-table__title ticket-table__title--total">
      subtotaal
    </div>
  </div>
  <div class="ticket-table__row">
    <div class="ticket-table__field ticket-table__field--tickets">
      tickets field
    </div>
    <div class="ticket-table__field ticket-table__field--price">
      prijs field
    </div>
    <div class="ticket-table__field ticket-table__field--amount">
      aantal field
    </div>
    <div class="ticket-table__field ticket-table__field--total">
      subtotaal field
    </div>
  </div>
  <div class="ticket-table__row">
    <div class="ticket-table__field ticket-table__field--tickets">
      tickets field
    </div>
    <div class="ticket-table__field ticket-table__field--price">
      prijs field
    </div>
    <div class="ticket-table__field ticket-table__field--amount">
      aantal field
    </div>
    <div class="ticket-table__field ticket-table__field--total">
      subtotaal field
    </div>
  </div>
</div>
<div class="ticket-table__footer">
  <div class="ticket-table__footer__label">total</div>
  <div class="ticket-table__footer__price">&euro; 17.50</div>
</div>

Style guide: components.checkout.ticket-table
*/

.ticket-table__row {
  align-items: center;
  display: grid;
  grid-template-columns: 2fr 1fr;
  padding-bottom: calc($spacing-l/2);
  padding-top: calc($spacing-l/2);

  @include tablet {
    grid-template-columns: 2fr 1fr 1fr 1fr;
    padding-bottom: calc($spacing-xl/2);
    padding-top: calc($spacing-xl/2);
  }

  & > *:last-child {
    justify-self: end;
  }
}

.ticket-table__title {
  @extend .title-xs;
  font-weight: 700;
  text-transform: uppercase;
}

.ticket-table__field {
  align-self: center;

  &.ticket-table__field--tickets,
  &.ticket-table__field--price {
    grid-column: 1 / 2;

    @include tablet {
      grid-column: auto / auto;
    }
  }

  &.ticket-table__field--price {
    color: $info-text-color-light;
    font-weight: bold;

    @include tablet {
      font-weight: normal;
    }
  }

  &.ticket-table__field--amount {
    justify-self: end;
    grid-column: 2 / 3;
    grid-row: 1 / 3;

    @include tablet {
      justify-self: start;
      grid-column: auto / auto;
      grid-row: auto / auto;
    }
  }

  &.ticket-table__field--total {
    justify-self: end;
  }
}

.ticket-table__footer {
  align-items: center;
  background: $background-light;
  display: grid;
  grid-column: 1 / -1;
  grid-template-columns: auto auto;
  height: 60px;
  margin-left: -$container-padding-mobile;
  margin-right: -$container-padding-mobile;
  margin-top: $spacing-ml;
  padding-left: $container-padding-mobile;
  padding-right: $container-padding-mobile;

  @include tablet {
    margin-left: 0;
    margin-right: 0;
    padding-left: 20px;
    padding-right: 10px;
  }
}

.ticket-table__footer__container {
  background: $background-light;
  margin-top: $spacing-ml;
  margin-bottom: $spacing-l;

  .ticket-table__footer {
    margin-top: 0;

    @include tablet {
      grid-column: 6 / 14;
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.ticket-table__title--total,
.ticket-table__field--total {
  justify-self: end;
}

.ticket-table__footer__label {
  font-size: 0.875em;
}

.ticket-table__footer__price {
  font-size: 1.125em;
  justify-self: end;
}

// hidden on mobile
.ticket-table__header {
  @extend .ticket-table__row;

  @include mobile-only {
    display: none;
  }
}

/*
Ticket table (order review)

Markup:
<div class="ticket-table--order-review">
  <div class="ticket-table__header">
    <div>
      Tickets
    </div>
    <div>
      <a
        class="order-review__btn-edit"
      >Edit link</a>
    </div>
  </div>
  <div class="ticket-table__row">
    <div class="ticket-table__field">
      tickets field
    </div>
    <div class="ticket-table__field">
      prijs field
    </div>
    <div class="ticket-table__field">
      aantal field
    </div>
    <div class="ticket-table__field">
      <strong>&euro; subtotaal</strong>
    </div>
  </div>
  <div class="ticket-table__row">
    <div class="ticket-table__field">
      tickets field
    </div>
    <div class="ticket-table__field">
      prijs field
    </div>
    <div class="ticket-table__field">
      aantal field
    </div>
    <div class="ticket-table__field">
      <strong>&euro; subtotaal</strong>
    </div>
  </div>
</div>

Style guide: components.checkout.ticket-table-order-review
*/

.ticket-table--order-review {
  // .ticket-table__row should be defined before .ticket-table__header
  // because of same specificity
  .ticket-table__row {
    grid-template-columns: 3fr 1fr 1fr 1fr;
    padding-top: 0;
    padding-bottom: 0;

    @include tablet {
      grid-template-columns: 2fr 1fr 1fr 2fr;
    }
  }

  .ticket-table__header {
    @extend .ticket-table__row;
    grid-template-columns: 1fr 1fr;
    padding-top: $spacing-m;
    padding-bottom: $spacing-ml;

    @include tablet {
      grid-template-columns: 1fr 1fr;
      padding-top: $spacing-xxl;
    }
  }

  .ticket-table__field {
    @extend small;
    align-self: start;
  }
}

.ticket-table__field--total {
  display: none;

  @include tablet {
    display: block;
  }
}
