.proposition-card {
  position: relative;
  background: $color-light;

  @include mobile-only {
    margin: 8px 0 0;
    padding: 100px 8px 8px;
  }

  @include tablet {
    display: flex;
    margin: $grid-gap 0 0;
    min-height: 200px;
  }
}

.proposition-card__hightlight {
  position: absolute;
  top: 20px;
  left: 0;
  padding: 4px 8px;
  background: $color-primary;
  color: $color-primary-negative;

  @include tablet {
    top: auto;
    bottom: 0;
    padding: 8px 16px;
  }
}

.proposition-card__content {
  position: relative;
  line-height: 140%;

  @include tablet {
    display: flex;
    flex-direction: column;
    padding: $grid-gap 0 0;
    line-height: 200%;
  }

  .title {
    font-size: 1em;
  }
}

.proposition-card__image {
  background: $color-light url("/assets/placeholder.jpg") no-repeat center;
  background-size: cover;

  @include mobile-only {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  @include tablet {
    flex: 0 0 32%;
    margin: 0 $grid-gap 0 0;
  }
}
