/*
Checkout Flow

Style guide: components.checkout
*/

/*
Checkout Container

Markup:
<div class="checkout__container">
  <div class="checkout__main-content" style="background: lightgrey">
    main content
  </div>
</div>

Style guide: components.checkout.container
*/

/*
Checkout Container with sidebar

Markup:
<div class="checkout__container has-sidebar">
  <div class="checkout__sidebar" style="background: lightgrey">
    sidebar
  </div>
  <div class="checkout__main-content" style="background: lightgrey">
    main content
  </div>
</div>

Style guide: components.checkout.container-sidebar
*/

$grid-breakpoint: "tablet-landscape";
$header-size: 158px;
$footer-size: 218px;

.checkout__container {
  @include grid-container($grid-breakpoint);
}

.checkout__main-content {
  @include media-query($grid-breakpoint) {
    grid-column: 5 / 11;

    .has-sidebar & {
      grid-column: 6 / 14;
    }
  }

  @include widescreen {
    .has-sidebar & {
      grid-column-end: 13;
    }
  }

  &.is-narrow {
    width: 100%;

    @include tablet {
      max-width: 340px;
    }
  }

  &.is-narrow--centered {
    width: 100%;

    @include tablet {
      grid-column: 6 / 10;
    }
  }
}

.checkout__sidebar {
  margin-left: -$container-padding-mobile;
  margin-right: -$container-padding-mobile;

  @include tablet {
    margin-left: 0;
    margin-right: 0;
    padding-top: $spacing-ml;
  }

  @include media-query($grid-breakpoint) {
    grid-column: 2 / 6;
  }

  @include widescreen {
    grid-column-start: 3;
  }
}

.checkout__content-block {
  padding-top: $spacing-ml;
  padding-bottom: $spacing-ml;

  .button--primary {
    margin: $spacing-m 0;
  }
}

.checkout-page-wrapper {
  min-height: 375px;

  @include tablet {
    min-height: 768px;
  }

  @include widescreen {
    min-height: 1280px;
  }
}
