// Filters
.filters-list {
  &.hide {
    display: none;
  }

  .option {
    display: flex;
    align-items: center;
    padding: 4px 0;
    cursor: pointer;

    .checkbox {
      position: relative;
      margin: 0 8px 0 0;
    }
  }

  .option--active {
    .checkbox:after {
      content: url("data:image/svg+xml;charset=utf-8,%3Csvg width='19' height='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.463 1.09L6.107 11.445 2.302 7.64a.472.472 0 00-.668 0L.521 8.754a.472.472 0 000 .668l5.252 5.252a.472.472 0 00.668 0L18.244 2.871a.472.472 0 000-.668L17.131 1.09a.472.472 0 00-.668 0z'/%3E%3C/svg%3E");
      position: absolute;
      top: 2px;
      left: 2px;
    }
  }

  .option--unavailable {
    opacity: 0.4;
  }
}

.ais-RefinementList {
  .ais-RefinementList-list {
    list-style-type: none;
    padding: 0;
  }

  .ais-RefinementList-label {
    display: flex;
    align-items: center;
    padding: 4px 0;
    cursor: pointer;

    input:checked + .ais-RefinementList-labelText:after {
      opacity: 1;
    }

    .ais-RefinementList-checkbox {
      display: none;
    }
  }

  .ais-RefinementList-showMore {
    margin-top: 6px;
    color: #999;
    font-size: 14px;
  }

  .ais-RefinementList-labelText {
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
      &:before {
        border-color: $color-primary;
      }
    }

    // Checkbox
    &:before {
      content: "";
      display: inline-block;
      border: 1px solid $color-accent-soft;
      margin: 0 8px 0 0;
      height: 24px;
      width: 24px;
      background: $color-accent-soft;
    }

    &:after {
      content: url("data:image/svg+xml;charset=utf-8,%3Csvg width='19' height='15' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.463 1.09L6.107 11.445 2.302 7.64a.472.472 0 00-.668 0L.521 8.754a.472.472 0 000 .668l5.252 5.252a.472.472 0 00.668 0L18.244 2.871a.472.472 0 000-.668L17.131 1.09a.472.472 0 00-.668 0z'/%3E%3C/svg%3E");
      position: absolute;
      top: 4px;
      left: 4px;
      opacity: 0;
    }
  }

  .ais-RefinementList-count {
    margin-left: 6px;
    align-self: flex-end;
    color: rgba($text-color, 0.4);
    font-size: 0.7em;

    &:before {
      content: "(";
    }
    &:after {
      content: ")";
    }
  }

  input[type="checkbox"] {
    margin: 0 8px 0 0;
    height: 20px;
    width: 20px;
  }

  // Show more
  .ais-RefinementList-showMore--disabled {
    display: none;
  }

  // Searchbox
  .ais-SearchBox-form {
    position: relative;
    margin: 4px 0;

    &:hover .ais-SearchBox-input {
      border-color: $color-primary;
    }
  }

  .ais-SearchBox-input {
    border: 1px solid $color-accent-soft;
    border-radius: 0;
    padding: 0 8px;
    min-height: 32px;
    background: $color-accent-soft;

    &:not(:focus) + .ais-SearchBox-submit {
      display: flex;
    }

    &:focus + .ais-SearchBox-reset {
      display: flex;
    }
  }

  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    display: none;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
  }
}

.ais-ClearRefinements {
  .ais-ClearRefinements-button--disabled {
    display: none;
  }
}

// Current filterlist
.ais-CurrentRefinements-item {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;

  margin-bottom: 16px;

  .ais-CurrentRefinements-label {
    display: none;
  }

  .ais-CurrentRefinements-category {
    margin: 0 4px 4px 0;
    padding: 1px 6px;
    font-size: 0.75em;
    background: $color-accent-soft;
  }

  .ais-CurrentRefinements-delete {
    cursor: pointer;
    opacity: 0.4;
    margin: 0 0 0 4px;

    &:hover {
      opacity: 1;
    }
  }
}

// Mobile
.algolia-search__sidebar {
  @include mobile-only {
    padding: 0 0 32px;
  }

  .button--filters {
    width: 100%;
  }

  .button--show-results {
    position: fixed;
    z-index: 1;
    bottom: 16px;
    left: $container-padding-mobile;
    width: calc(100% - #{$container-padding-mobile * 2});
  }

  .button--filters,
  .filters-lists__header,
  .button--show-results {
    @include tablet {
      display: none;
    }
  }

  .filters-header {
    @include mobile-only {
      margin-top: 16px;
    }
  }
}

@include mobile-only {
  .filters-lists {
    position: fixed;
    overflow-y: scroll;
    scroll-behavior: smooth;
    z-index: 11;
    top: 0;
    left: 0;
    padding-bottom: 100px;
    height: 100vh;
    width: 100vw;
    background: #fff;
    transition: transform 0.2s;

    &:not(.filters-lists--open) {
      transform: translateX(-100%);
    }

    .filters-list {
      margin-left: $container-padding-mobile;
      margin-right: $container-padding-mobile;
    }

    .button--close {
      border: none;
      padding: 0;
      font-size: 22px;
    }
  }

  .filters-lists__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    padding: $container-padding-mobile;
    background: $color-accent-soft;
  }
}
