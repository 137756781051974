.fourzerofour {
  @include tablet {
    min-height: 75vh;
  }
}

.sky {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: $color-primary;
  color: $color-primary-negative;
  height: 36vh;

  @include tablet {
    position: absolute;
    top: 0;
    left: 0;

    height: calc(75vh + #{$navigation-height--desk});
    width: 50vw;
  }
}

.fourzerofour__content {
  @include flex-center;
  flex-direction: column;
  padding: $spacing-l $container-padding-mobile;

  @include tablet {
    margin-left: auto;
    padding: $spacing-l $spacing-l #{$spacing-l * 3};
    height: 75vh;
    width: 50vw;
  }

  h1 {
    font-size: 4em;
    color: $color-primary;
  }

  p {
    margin: $spacing-m 0 $spacing-l;
    max-width: 350px;
    text-align: center;
  }
}

@keyframes animateCloud {
  0% {
    margin-left: -500px;
  }
  100% {
    margin-left: 100%;
  }
}

.c1 {
  animation: animateCloud 35s linear infinite;
  animation-delay: -9s;
  transform: scale(0.65);
}

.c2 {
  animation: animateCloud 20s linear infinite;
  animation-delay: -9s;
  transform: scale(0.3);
}

.c3 {
  animation: animateCloud 30s linear infinite;
  animation-delay: -9s;
  transform: scale(0.5);
}

.c4 {
  animation: animateCloud 18s linear infinite;
  animation-delay: -9s;
  transform: scale(0.4);
}

.c5 {
  animation: animateCloud 25s linear infinite;
  animation-delay: -9s;
  transform: scale(0.55);

  @include mobile-only {
    display: none;
  }
}

.cloud {
  position: relative;
  height: 60px;
  width: 175px;
  border-radius: 100px;
  background: #fff;

  @include tablet {
    height: 120px;
    width: 350px;
  }
}

.cloud:after,
.cloud:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: #fff;
}

.cloud:after {
  top: -24px;
  left: 27px;
  height: 60px;
  width: 60px;
  border-radius: 100px;

  @include tablet {
    top: -50px;
    left: 50px;
    height: 100px;
    width: 100px;
  }
}

.cloud:before {
  top: -36px;
  right: 25px;
  height: 80px;
  width: 80px;
  border-radius: 200px;

  @include tablet {
    top: -90px;
    right: 50px;
    height: 180px;
    width: 180px;
  }
}
