.login-cjp {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;

  padding-top: $navigation-height--mobile;

  @include tablet-landscape {
    padding-top: $navigation-height--desk;
  }

  .background-image {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-width: 60vw;
    mask-image: url('data:image/svg+xml, <svg width="1302" height="1758" xmlns="http://www.w3.org/2000/svg"><path d="M1302 0v1758c-704.51-.02-1059.665-.02-1065.464 0C91.449 1542.707 0 1229.279 0 879 0 528.983 91.313 215.263 236.21 0H1302Z" fill="%23D8D8D8" fill-rule="nonzero"/></svg>');
    mask-size: cover;

    @include tablet-landscape {
      max-width: 40vw;
    }

    picture {
      height: 100%;
      width: 100%;
    }
  }

  .login-navigation {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: $navigation-height--mobile;

    @include tablet-landscape {
      height: $navigation-height--desk;
    }

    .logo img {
      max-height: 32px;
    }
  }
}

.login-pane-cjp {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  border-radius: 20px;
  border: 1px solid #b2b2b2;
  padding: 32px 24px;
  width: 100%;
  max-width: 640px;
  background: #fff;

  @include tablet-landscape {
    padding: 40px;
  }

  .field {
    position: relative;

    .label {
      font-weight: bold;
      margin-left: 16px;
    }

    .button--primary {
      margin-top: 40px;
      min-width: 240px;
    }

    .password-icon {
      position: absolute;
      transform: translateY(-50%);
      top: 59%;
      right: 25px;
      z-index: 10;
      cursor: pointer;
      user-select: none;
    }
  }

  .form-footer {
    margin-top: 32px;

    @include tablet-landscape {
      display: flex;
      justify-content: space-between;
    }
  }

  .form-footer__link {
    color: $color-primary;

    &:before {
      content: "\f054";
      @include font-awesome;
      margin-right: 6px;
      font-weight: 600;
    }
  }

  .form-footer__support {
    @include until-tablet-landscape {
      display: flex;
      justify-content: flex-end;
      margin-top: 16px;
    }

    a {
      display: flex;
      align-items: center;
    }

    .icon {
      @include flex-center;
      margin-left: 12px;
      height: 60px;
      width: 60px;
      border-radius: 100%;
      background: #b2b2b2;
      color: $color-white;
      font-size: 24px;
    }
  }

  .close {
    position: absolute;
    top: 40px;
    right: 40px;
    cursor: pointer;

    i {
      margin-left: 4px;
    }
  }
}

.login-pane-cjp__help-text {
  padding: 0 0 32px 16px;
}
