.button--robin {
  position: fixed;
  z-index: 99;
  bottom: 20px;
  right: 20px;

  background: $color-primary;
  color: $text-color;
  border-color: currentColor;

  @include on-event {
    text-decoration: none;

    background: $color-primary;
    color: $text-color;
  }

  @include tablet {
    bottom: 32px;
    right: 50px;
  }
}
