@include tablet {
  body *.mobile {
    display: none;
  }
}

@include mobile-only {
  .tablet {
    display: none;
  }
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-top: $navigation-height--mobile;

  @include tablet {
    padding-top: $navigation-height--desk;
  }

  &.has-no-navbar {
    padding-top: 0;
  }
}

.grid-container {
  position: relative;

  display: flex;
  flex-direction: column;

  padding-left: $container-padding-mobile;
  padding-right: $container-padding-mobile;
  width: 100%;

  @include tablet {
    display: grid;
    grid-template-columns: 0 repeat(12, 1fr) 0;
    grid-column-gap: $grid-gap;
    max-width: 100%;
    padding: 0;
  }

  @include container-min {
    grid-template-columns:
      1fr repeat(12, #{(calc(calc($grid-width - (11 * $grid-gap)) / 12))})
      1fr;
  }
}

.container {
  padding-left: $container-padding-mobile;
  padding-right: $container-padding-mobile;
  width: 100%;

  @include tablet {
    padding-left: $container-padding;
    padding-right: $container-padding;
  }

  @include container-min {
    margin: 0 auto;
    max-width: $grid-width + (2 * $container-padding);
  }
}

.component {
  grid-column: 2 / -2;
  padding: 40px 0;
}
