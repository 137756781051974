/*
Sticky bar 

Markup:
<div class="dummy-wrapper" style="height: 300px; border: 1px solid grey">
  <div class="dummy-content" style="height: 300px">
    regular content pushing bar down
  </div>
  <div class="sticky-bar--bottom">
    <div class="dummy" style="background: lightgrey; height: 100px">
      Content in sticky bar
    </div>
  </div>
</div>
<div style="height: 200px">
  Following element
</div>

Style guide: components.sticky-bar
*/

.sticky-bar,
[class^="sticky-bar--"] {
  margin: 0;
  padding: 0;
  position: sticky;
  z-index: 10;
}

.sticky-bar--bottom {
  bottom: 0;
}
