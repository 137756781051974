/*
Alert Message

When packed in container '.alert__container', alert will be
fixed to bottom

.is-info - Info message
.is-success - Success message
.is-warning - Warning message
.is-danger - Error message

Markup:
<div class="alert {{modifier_class}}">
  <div class="alert__message">Message</div>
  <button class="alert__btn-close"">
    &times;
  </button>
</div>

Style guide: components.alert-message
*/

.alert__container {
  position: fixed;
  bottom: 0;
  z-index: 10;
}

.alert {
  align-items: center;
  background-color: $color-light;
  display: flex;
  grid-column: 2 / -2;
  justify-content: space-between;
  margin: 0 0.75em;
  padding: 0.75em;

  &.is-warning,
  &.is-success,
  &.is-danger {
    background-color: $color-red;

    .alert__message,
    .alert__btn-close {
      color: white;
    }
  }
}

.alert__message {
  color: $color-light-negative;

  a {
    color: inherit;
    text-decoration: underline;
  }
}

.alert__btn-close {
  cursor: pointer;
  color: $color-light-negative;
  font-size: 1.5em;
  font-weight: 700;
}

.alert-slide-fade-enter-active {
  transition: opacity $modal-transition-speed, transform $modal-transition-speed;
}
.alert-slide-fade-leave-active {
  transition: opacity $modal-transition-speed, transform $modal-transition-speed;
}
.alert-slide-fade-enter,
.alert-slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
