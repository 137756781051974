// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}

@mixin when-after($context) {
  #{$context} + & {
    @content;
  }
}

@mixin basic-reset {
  list-style: none;
  background: none;
  text-decoration: none;
  border: none;
  margin: 0;
  padding: 0;
}

@mixin limit-lines($amount) {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $amount;
  -webkit-box-orient: vertical;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin skeleton-loader {
  background: $color-light;
  animation: pulse 2s infinite;

  &:nth-child(2) {
    background: rgba($color-light, 0.8);
    animation-delay: 0.2s;
  }
  &:nth-child(3) {
    background: rgba($color-light, 0.6);
    animation-delay: 0.2s;
  }
  &:nth-child(4) {
    background: rgba($color-light, 0.4);
    animation-delay: 0.4s;
  }
  &:nth-child(n + 5) {
    background: rgba($color-light, 0.2);
    animation-delay: 0.6s;
  }
}

@mixin grid-container(
  $breakpoint: "tablet",
  $mobile-display: "block",
  $column-count: $grid-column-count,
  $column-count--mobile: $grid-column-count--mobile
) {
  display: $mobile-display;
  flex-direction: column;
  padding-left: $container-padding-mobile;
  padding-right: $container-padding-mobile;
  width: 100%;

  @if ($mobile-display == "grid") {
    grid-column-gap: $grid-gap--mobile;
    grid-template-columns: 0 repeat($column-count--mobile, 1fr) 0;
    padding-left: 0;
    padding-right: 0;
  }

  @if ($breakpoint != tablet) {
    @include tablet {
      padding-left: $grid-gap;
      padding-right: $grid-gap;
    }
  }

  @include media-query($breakpoint) {
    display: grid;
    grid-template-columns: 0 repeat($column-count, 1fr) 0;
    grid-column-gap: $grid-gap;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  @include container-min {
    $column-width: #{(calc(calc($grid-width - (($column-count - 1) * $grid-gap)) / $column-count))};

    grid-template-columns: 1fr repeat($column-count, $column-width) 1fr;
  }
}

@mixin imageGradientOverlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
}

@mixin font-awesome {
  display: inline-block;
  margin-right: $spacing-s;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}
