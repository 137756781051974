.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  &:before {
    content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><g fill="none" fill-rule="evenodd" stroke-width="3"><circle stroke="%23FFF" opacity=".5" cx="14" cy="14" r="12"/><path d="M22.53 22.441A11.962 11.962 0 0026 14c0-6.627-5.373-12-12-12" stroke="%23FF71EC" stroke-linecap="round"/></g></svg>');
    height: 75px;
    width: 75px;
    animation: spin 1.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
}
