.all-products {
  padding-bottom: $spacing-m;

  .title-l {
    margin: $spacing-l 0 0;

    @include tablet {
      margin: $spacing-m 0;
    }
  }

  .product-list {
    @include tablet {
      grid-template-columns: 1fr 1fr 1fr;
    }

    @include desktop {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .tile {
      @include tablet-only {
        &:nth-child(6n + 1),
        &:nth-child(6n + 5) {
          grid-column: span 2;
          grid-row: span 2;

          .title-m {
            font-size: 2.5em;
          }
        }
      }

      @include desktop {
        &:nth-child(11n + 1) {
          grid-column: span 2;
          grid-row: span 2;

          .title-m {
            font-size: 2.5em;
          }
        }
      }
    }
  }

  .load-more {
    margin: $spacing-m auto 0;
    width: 350px;

    @include mobile-only {
      margin: $spacing-m 0;
      width: 100%;
    }
  }
}
