.ideal-selector {
  padding-bottom: $spacing-m;
}

.ideal-selector__title {
  @extend .title-s;
  margin-bottom: $spacing-m;
}

.ideal-selector__selected-issuer {
  @extend small;
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;

  > *:last-child {
    justify-self: end;
  }
}

.ideal-selector__modal {
  bottom: 0;
  left: 0;
  right: 18px;
  top: 0;
  padding-left: $container-padding-mobile;
  padding-right: $container-padding-mobile;
  position: fixed;
  background: $background-dark-opposite;
  box-shadow: $box-shadow;
  z-index: 10;
}

.ideal-selector__modal__btn-close {
  background: $color-accent-soft;
  border-radius: 50%;
  height: 2.625em;
  line-height: 2.625em;
  text-align: center;
  width: 2.625em;

  @include on-event {
    cursor: pointer;
  }
}

.ideal-selector__modal__header {
  padding-bottom: 14px;
  padding-top: 38px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@include tablet {
  .ideal-selector__modal {
    right: 0;
  }

  .ideal-selector__modal__header,
  .ideal-selector__modal__content,
  .ideal-selector__modal__footer button {
    margin-left: auto;
    margin-right: auto;
    width: 340px;
  }
}

.ideal-selector__modal__footer {
  background: $color-accent-soft;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: $container-padding-mobile;
  padding-right: $container-padding-mobile;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.slide-modal-leave-active,
.slide-modal-enter-active {
  transition: $modal-transition-speed;
}

.slide-modal-enter,
.slide-modal-leave-to {
  transform: translateX(-100%);

  @include tablet {
    transform: translateY(100%);
  }
}
