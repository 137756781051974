.block--articles {
  .block__description {
    grid-column: 2 / -2;
    grid-row: 1 / 2;
  }

  .block__content {
    grid-column: 2 / -2;
    grid-row: 2 / 3;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: $grid-gap;

    .article-tile {
      background: #f2f2f2;
    }
  }
}
