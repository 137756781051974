/*
Colors

Style guide: 1-abstracts.colors
 */

/*
Base Colors

Colors:
$color-primary: #2f4e5f - Description?
$color-primary-negative: #ffffff - If you use color-primary as background, this is the correct text-color
$color-light: #e5ebef
$color-light-negative: #2f4e5f - If you use color-light as background, this is the correct text-color
$color-white: #ffffff

Style guide: 1-abstracts.colors.1-base
 */

//## Colors ##
$color-primary: #00acec;
$color-primary-negative: #000000; //If you use color-primary as background, this is the correct text-color

$color-light: #e5ebef;
$color-light-negative: $color-primary; //If you use color-light as background, this is the correct text-color

$color-white: #ffffff;

/*
Highlight Colors

Colors:
$color-green: #01982b - Green info color
$color-green-light: #5dcd88 - Brighter green
$color-purply-pink: #ff71ec - Purply pink

Style guide: 1-abstracts.colors.2-highlight
 */
$color-green: #01982b;
$color-green-light: #5dcd88;
$color-purply-pink: #ff71ec;
$color-red: #ff0000;

/*
Support Colors

Colors:
$background-light: #f2f2f2 - Light grey
$background-dim: #82829a - Grey
$background-dark: #2f4e5f - Grey
$background-dark-opposite: #ffffff - White
$text-color: #08152a - Text

Style guide: 1-abstracts.colors.3-support
 */
$background-lightest: #ffffff;
$background-light: #f2f2f2;
$background-dim: #82829a;
$background-dark: #000000;
$background-dark-opposite: #ffffff;

$text-color: #000000;
$link-color: $color-primary;

$info-text-color: $color-green;
$info-text-color-light: $color-green-light;

$danger-text-color: $color-red;

$color-accent-hard: $color-primary;

$color-accent-soft: #eaeaee;
$color-accent-light: $color-primary;

//## ELEMENTS ##
$button-primary-background: $color-primary;
$button-primary-color: $color-primary-negative;

$button-secondary-background: $color-light;
$button-secondary-color: $color-light-negative;

$button-light-background: $color-white;
$button-light-color: $color-primary;

$input-background-color: #ffffff;
$input-color: $text-color;
$input-placeholder-color: $background-dim;
$input-border-color: #000000;
$input-focus-border-color: $color-primary;

$input-error-background: #fae5ea;
$input-error-color: $input-color;
$input-error-border: #ce0033;

$input-disabled-background: $color-accent-soft;
$input-disabled-color: #cbc9c7;
$input-disabled-border: #e1e1e1;

$input-dark-border: #000000;
$input-dark-background: #666666;
$input-dark-placeholder: #ffffff;
$input-dark-color: #ffffff;

$checkbox-background: $color-accent-soft;
$checkbox-border-color: $color-accent-soft;
$checkbox-border-radius: 0;

$input-radio-background: $color-accent-soft;
$input-radio-checked-background: $text-color;

//## COMPONENTS ##
// Header
$header-background-color: transparant;
$header-color: $text-color;
$header-border-bottom-color: transparant;

// Footer
$color-light-accent: #000000;

// CJP specific
$border-color: #b2b2b2;

$label-background: #e7f03a;
