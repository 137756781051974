.related-products {
  padding: $spacing-xl 0;

  .title {
    grid-column: 2 / -2;
    margin-bottom: $spacing-m;

    @include mobile-only {
      margin-left: $container-padding-mobile;
    }
  }

  .product-list {
    grid-column: 2 / -2;

    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $spacing-ms;

    @include tablet {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: $grid-gap;
    }

    @include desktop {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    .tile {
      margin: 0;
      width: 100%;

      @include tablet-only {
        &:nth-child(6n + 2),
        &:nth-child(6n + 4) {
          grid-column: span 2;
          grid-row: span 2;

          .title-m {
            font-size: 2.5em;
          }
        }
      }

      @include desktop {
        &:nth-child(7n) {
          grid-column: span 2;
          grid-row: span 2;

          .title-m {
            font-size: 2.5em;
          }
        }
      }
    }
  }
}
