.block--product-categories {
  @include mobile-only {
    .grid-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .title {
    grid-column: 2 / -2;
    grid-row: 1 / 2;

    @include mobile-only {
      margin-left: $container-padding-mobile;
    }
  }
}
