.search-item {
  display: flex;
  margin: 0 0 16px;
  padding: 0 0 16px;
  border-bottom: 1px solid #f2f2f2;

  .item__image {
    margin: 0 12px 0 0;
    height: 70px;
    flex: 0 0 70px;
    background: #f2f2f2;

    @include tablet {
      height: 110px;
      flex: 0 0 110px;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .item__title {
    color: $color-primary;
    font-size: 1.125em;
    font-weight: 400;
  }

  .item__paragraph {
    margin: 8px 0 0;
  }

  .item__type {
    margin: 0 0 4px;
    font-size: 12px;
    color: $color-accent-soft;
  }

  .item__content {
    padding: 6px 0;
  }

  .item__breadcrumbs {
    margin: 0 0 4px;

    .breadcrumb-item {
      font-size: 12px;
      color: $color-accent-soft;
    }

    .breadcrumb-chevron {
      margin: 0 8px;
    }
  }
}
