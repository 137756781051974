.navigation {
  box-sizing: content-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  height: $navigation-height--mobile;
  width: 100vw;
  color: $header-color;
  background: $header-background-color;
  transition: all $general-transition-speed;

  @include until-tablet-landscape {
    border-bottom: $navigation-border-bottom;
    border-color: $header-border-bottom-color;
  }

  @include tablet-landscape {
    height: $navigation-height--desk;
  }

  .container {
    position: relative;
    z-index: 1;
    background: $header-background-color;

    @include until-tablet-landscape {
      display: grid;
      grid-template-columns: 88px auto 88px;
      grid-template-rows: $navigation-height--mobile;
      align-items: center;
      justify-items: center;
      padding: 0;
    }

    @include tablet-landscape {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: inherit;
    }
  }

  &.hidden {
    transform: translateY(-100%);
  }

  .button,
  [class^="button--"],
  .regular {
    margin: 0 0 0 $spacing-l;

    .icon {
      margin: 0 8px 0 0;
    }

    &.active {
      font-weight: 700;
    }
  }

  .regular {
    text-decoration: none;
    color: $text-color;
  }

  .button--solid {
    background: $color-white;
    color: $text-color;
  }

  .nav-button--switch,
  .nav-button--search {
    display: flex;
    align-items: center;
    justify-content: center;

    color: $text-color;
    height: 100%;
    width: 64px;
    outline: none;
    justify-self: left;

    @include tablet-landscape {
      display: none;
    }
  }

  .nav-button--search {
    order: 3;
    justify-self: flex-end;

    &.nav-button--desktop {
      display: none;
      margin: 0 0 0 auto;
      width: 42px;

      @include tablet-landscape {
        display: flex;
      }
    }
  }

  .logo {
    order: 1;
    img {
      max-height: 40px;
      max-width: 200px;
    }
  }

  .logo--inverted {
    display: none;
  }
}

.navigation__menu {
  display: flex;
  align-items: center;

  @include until-tablet-landscape {
    display: none;
  }
}

.navigation__menu--secondary {
  order: 2;
}

.navigation__menu--main {
  order: 4;
}
