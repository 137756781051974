.category-filters-list {
  margin: 16px 0 0;

  .search-query {
    cursor: pointer;
    display: flex;
    margin: 0 0 32px;
    padding: 4px 8px;
    background: $color-light;
    border-radius: $border-radius;

    .fas {
      margin: 2px 0 -2px auto;
    }
  }
}

.category-filters__header {
  font-weight: bold;
  margin-bottom: $spacing-l;
}

.category-filters.mobile {
  padding-top: $spacing-m;

  .title-m--alt {
    margin-bottom: $spacing-m;
  }
}

.category-filters.tablet {
  grid-column: 2 / 5;
  align-self: flex-start;

  .button {
    width: fit-content;
  }
}
