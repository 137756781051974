.pop-up {
  position: fixed;
  z-index: 11;
  top: 0;
  left: 0;
  display: none;
  align-items: center;
  justify-content: center;
  padding: $spacing-m;
  height: 100vh;
  width: 100vw;

  &.show {
    display: flex;
  }

  .logo {
    align-self: flex-start;
  }
}

.pop-up__background {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.pop-up__content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 16px 16px;
  max-width: 400px;
  text-align: center;
  background: #fff;
  box-shadow: 0 0 2000px rgba(0, 0, 0, 0.5);

  .emoticon {
    margin: 20px 0 -35px;
    max-width: 90%;

    @include tablet {
      margin: 32px -100px -7vh;
      max-width: 500px;
      max-height: 40vh;
    }
  }

  p {
    margin: 8px 0 16px;
  }

  h2 {
    text-shadow: 0 0 15px #fff, 0 0 15px #fff, 0 0 15px #fff, 0 0 15px #fff;

    @include mobile-only {
      font-size: 2em;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;

    button {
      width: calc(50% - 4px);
    }
  }

  .close {
    position: absolute;
    top: $spacing-m;
    right: $spacing-m;
    font-size: 32px;
    cursor: pointer;
  }
}

.pop-up--budget {
  width: 100%;

  .pop-up__content {
    padding: 40px 16px 16px;
  }

  .title {
    font-size: 2.2em;
    letter-spacing: 1.5px;
  }

  p {
    margin: 32px 0 24px;
  }

  .buttons {
    flex-direction: column;

    button {
      margin-top: 8px;
      width: 100%;
    }
  }
}
