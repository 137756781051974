.select-box {
  position: relative;
  background: $color-light;
  border-radius: $border-radius;

  .value {
    display: flex;
    align-items: center;
    padding: 8px 16px 8px 8px;
    height: 42px;

    i {
      margin: 0 0 0 auto;
    }
  }

  .options {
    position: absolute;
    overflow: hidden;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    border-radius: $border-radius;
    box-shadow: $box-shadow;

    .show-more {
      @extend small;
    }

    input {
      border: none;
      padding: 0 $spacing-m;
      border-radius: 0;
      border-bottom: 2px solid $color-accent-soft;
      height: $spacing-xxl;
    }
  }

  .option,
  .show-more {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $color-accent-soft;
    padding: 0 $spacing-m;
    height: $spacing-xxl;

    &:last-child {
      border-bottom: none;
    }

    &:not(.available) {
      opacity: 0.6;
    }

    &.selected {
      background: $button-secondary-background;
      color: $button-secondary-color;
    }
  }

  .no-search-result {
    padding: $spacing-s $spacing-m;
  }
}

.select-box--multiple {
  &:before,
  .value {
    border-radius: 0;
  }

  &:first-of-type {
    &:before,
    .value {
      border-radius: $border-radius $border-radius 0 0;
    }
  }

  &:last-of-type {
    &:before,
    .value {
      border-radius: 0 0 $border-radius $border-radius;
    }
  }
}
