// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any,
// and all typgraphic styling (H1 - H6, p, strong, em, i, blockquote)
// -----------------------------------------------------------------------------
//
// When preferred or needed, @font-face declarations can also be moved to
// separate _fonts.scss
// -----------------------------------------------------------------------------

/*
Typography

Typographic elements

Style guide: 2-base.typography
*/

body {
  font-family: $text-font-stack;
  color: $text-color;
  line-height: 160%;
}

/*
Headings

Markup:
<h1 class="{{modifier_class}}">h1 Fontfamily weight ##/## (size/line-height)</h1>
<h2 class="{{modifier_class}}">h2 Fontfamily weight ##/## (size/line-height)</h2>
<h3 class="{{modifier_class}}">h3 Fontfamily weight ##/## (size/line-height)</h3>
<h4 class="{{modifier_class}}">h4 Fontfamily weight ##/## (size/line-height)</h4>
<h5 class="{{modifier_class}}">h5 Fontfamily weight ##/## (size/line-height)</h5>
<h6 class="{{modifier_class}}">h6 Fontfamily weight ##/## (size/line-height)</h6>

Style guide: 2-base.typography.headings
*/

/*
Title Sizes

Same headings are not always styled the same.
Use any heading with a .title- modifier for styling. Here seen with <H4>

.title-xs - 14px regular
.title-s - 16px medium
.title-m--alt - 20px medium
.title-m - 20px bold
.title-l - 30px bold
.title-xl - m: 50px bold | t: 48px bold

Markup:
<h4 class="{{modifier_class}}">h4 Fontfamily weight ##/## (size/line-height)</h4>

Style guide: 2-base.typography.title-sizes
*/

.title-xl,
.title-l,
.title-m,
.title-m--alt,
.title-s,
.redactor h1,
.redactor h2,
.redactor h3,
.redactor h4,
.redactor h5,
.redactor h6 {
  font-family: $title-font-stack;
  line-height: 110%;
}

.title-xl,
.redactor h1 {
  //50px - bold
  font-size: 3.125em;
  font-weight: 700;

  @include tablet {
    //48px - bold
    font-size: 3em;
  }
}

.title-l,
.redactor h2 {
  //30px - bold
  font-size: 1.875em;
  font-weight: 700;
}

.title-m,
.redactor h3 {
  //20px - bold
  font-size: 1.25em;
  font-weight: 700;
  line-height: 140%;
}

.title-m--alt {
  //20px - medium
  font-size: 1.25em;
  font-weight: 500;
}

.title-s,
.redactor h4 {
  //16px - medium
  font-size: 1em;
  font-weight: 500;
}

.title-xs,
.redactor h5 {
  //14px - regular
  font-size: 0.875em;
  font-weight: normal;
}

.text {
  &--error {
    color: $danger-text-color;
  }
}

.redactor {
  > *:first-child {
    margin-top: 0;
  }

  p,
  pre,
  ul,
  ol,
  blockquote {
    margin-bottom: $spacing-ms;

    &:last-child {
      margin-bottom: 0;
    }
  }

  pre {
    padding: $spacing-ms;
    background: $background-light;
    white-space: pre-line;
  }

  ul,
  ol {
    margin-left: 22px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: $spacing-l;
    margin-bottom: 4px;
  }

  figcaption {
    font-style: italic;
    font-size: $font-size-small;
  }

  figure {
    margin: $spacing-l 0;

    a {
      display: block;
      line-height: 0;
    }
  }

  blockquote {
    border-left: 3px solid rgba($text-color, 0.2);
    padding-left: $spacing-s;
    font-style: italic;
    color: rgba($text-color, 0.5);
  }
}

/*
Text

Markup:
<strong>strong Fontfamily weight ##/## (size/line-height)</strong><br />
<p>
  p Fontfamily weight ##/## (size/line-height)<br />
  Jelly-o cake cake cake gingerbread apple pie tart. Gingerbread
  dragée chupa chups jelly-o. Gingerbread jelly tootsie roll gummi
  bears gummies tart.
</p>

Style guide: 2-base.typography.text
*/

small {
  font-size: calc(13/16) * 1em;
}
