// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any,
// and all typgraphic styling (H1 - H6, p, strong, em, i, blockquote)
// -----------------------------------------------------------------------------

@font-face {
  font-family: "Formula";
  src: url("/assets/fonts/FormulaCondensed-Bold.woff2") format("woff2"),
    url("/assets/fonts/FormulaCondensed-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
  unicode-range: U+000-5FF;
}

/*
Typography

Typographic elements

Style guide: 2-base.typography
*/

body {
  font-family: $text-font-stack;
  color: $text-color;
  line-height: 140%;
}

/*
Headings

Markup:
<h1 class="{{modifier_class}}">h1 Fontfamily weight ##/## (size/line-height)</h1>
<h2 class="{{modifier_class}}">h2 Fontfamily weight ##/## (size/line-height)</h2>
<h3 class="{{modifier_class}}">h3 Fontfamily weight ##/## (size/line-height)</h3>
<h4 class="{{modifier_class}}">h4 Fontfamily weight ##/## (size/line-height)</h4>
<h5 class="{{modifier_class}}">h5 Fontfamily weight ##/## (size/line-height)</h5>
<h6 class="{{modifier_class}}">h6 Fontfamily weight ##/## (size/line-height)</h6>

Style guide: 2-base.typography.headings
*/

/*
Title Sizes

Same headings are not always styled the same.
Use any heading with a .title- modifier for styling. Here seen with <H4>

.title-xs - 14px regular
.title-s - 16px medium
.title-m--alt - 20px medium
.title-m - 20px bold
.title-l - 30px bold
.title-xl - m: 50px bold | t: 48px bold

Markup:
<h4 class="{{modifier_class}}">h4 Fontfamily weight ##/## (size/line-height)</h4>

Style guide: 2-base.typography.title-sizes
*/

.title-xl,
.title-l,
.title-m,
.title-m--alt,
.title-s,
.redactor h1,
.redactor h2,
.redactor h3,
.redactor h4,
.redactor h5,
.redactor h6 {
  font-family: $title-font-stack;
  font-weight: normal;
  line-height: 110%;
  letter-spacing: 1.5px;
}

.title-xl,
.redactor h1 {
  font-size: 3.125em;

  @include tablet {
    font-size: 3em;
  }
}

.title-l,
.redactor h2 {
  font-size: 2em;

  @include tablet {
    font-size: 3.2em;
    letter-spacing: 3.5px;
  }
}

.title-m,
.redactor h3 {
  font-size: 1.25em;
  line-height: 130%;
  font-weight: bold;
}

.title-m--alt {
  font-size: 1.25em;
}

.title-s,
.redactor h4 {
  font-size: 1em;
}

.title-xs,
.redactor h5 {
  font-size: 0.875em;
}

p {
  a {
    color: $color-primary;
  }
}
