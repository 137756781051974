.category-overview {
  margin-top: $spacing-m;
}

.category-overview__content {
  margin: $spacing-s 0 $spacing-l;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: $grid-gap;

  @include tablet {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include desktop {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .category {
    position: relative;
    padding: 100% 0 0;
    background: rgba(0, 0, 0, 0.05);

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .category__content {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .image {
      margin: 0 0 $spacing-m;
      max-width: 50%;
      max-height: 50%;
    }
  }
}
