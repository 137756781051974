.discount-label {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  padding: 0 $spacing-ms;
  height: $spacing-l;
  background: $color-white;
  color: $text-color;
  font-size: 14px;
  font-weight: bold;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: -20px;
    width: 0;
    height: 0;
    border-top: 40px solid $color-white;
    border-right: 20px solid transparent;
  }
}
