/*
PDF ticket

PDF tickets are generated by the backend. 
Keeping this pure CSS (no scss code) for easier copy-pasting

Markup:
<table class="pdf-ticket__wrapper">
  <tr>
    <td class="pdf-ticket__codes">
      <div class="pdf-ticket__codes__qrcode">
        <img src="https://via.placeholder.com/300x300/2f4e5f/ffffff?text=QRCODE" />
      </div>
      <div class="pdf-ticket__codes__barcode">
        <img src="https://via.placeholder.com/1800x100/2f4e5f/ffffff?text=BARCODE" />
      </div>
    </td>
    <td class="pdf-ticket__details">
      <h2>IntoCulture demo <small>market-name</small></h2>
      <div class="panel-body">
        <h3>Rembrandthuis Volwassenen <small>variant</small></h3>
        <ul>
          <li><strong>Customer name</strong></li>
          <li>Firstname Lastname</li>
          <li><strong>Customer id</strong></li>
          <li>0jig2MkcOF</li>
          <li><strong>Ticket price</strong></li>
          <li>&euro; 14.00</li>
          <li><strong>Date</strong></li>
          <li>2021-01-31</li>
        </ul>
      </div>
    </td>
  </tr>
</table>

Style guide: pdf-ticket
*/

.pdf-ticket__wrapper {
  position: relative;
  width: 100%;
}

.pdf-ticket__codes {
  width: 300px;
}

.pdf-ticket__codes__qrcode {
  margin-bottom: 24px;
}

.pdf-ticket__codes__qrcode,
.pdf-ticket__codes__qrcode img {
  height: 300px;
  width: 300px;
}

.pdf-ticket__codes__barcode,
.pdf-ticket__codes__barcode img {
  width: 300px;
}

.pdf-ticket__details {
  padding-left: 24px;
  vertical-align: top;
}

.pdf-ticket__details ul li {
  list-style: none;
}
