.navigation-mobile {
  position: fixed;
  top: $navigation-height--mobile;
  left: calc(-100% + 10px);
  z-index: 7;
  overflow-y: scroll;

  height: calc(100vh - #{$navigation-height--mobile});
  width: 300px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0);
  transition: top $general-transition-speed, left $general-transition-speed;

  @include mobile-only {
    width: 90vw;
  }

  a {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 $spacing-m;
    min-height: 60px;
    width: 100%;
    color: $text-color;

    .icon {
      margin: 0 8px 0 0;
    }

    &.active {
      font-weight: 700;

      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 8px;
        background: $color-primary;
      }
    }
  }

  .button--solid {
    justify-content: flex-start;
    font-weight: 400;
  }

  .navigation__menu {
    display: flex;
    flex-direction: column;
    margin: 0;

    &--main {
      margin-top: auto;
      margin-bottom: $spacing-ml;
      padding-bottom: $spacing-l;
      flex-direction: column-reverse;

      a:after {
        content: "\f054";
        @include font-awesome;

        margin: 0 0 0 auto;
        font-weight: 700;
      }
    }

    &--secondary {
      a {
        &:first-child {
          border-top: none;
        }
      }
    }
  }

  .navigation__categories {
    display: flex;
    flex-direction: column;

    .image {
      margin-right: $spacing-s;
      max-width: 60px;
      height: 34px;

      img {
        max-height: 100%;
      }
    }
  }

  .account-button {
    width: 100%;
  }

  .navigation__menu--secondary {
    padding-bottom: 201px;
    background: #f2f2f2;

    > * {
      background: #fff;
    }
  }
}

.navigation-click-area {
  display: none;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
}

//Set scroll lock on menu-open
html.menu-open,
html.menu-open body {
  overflow: hidden;

  //Show menu
  .navigation-mobile {
    left: 0;
    box-shadow: $box-shadow;
  }

  //show clickarea
  .navigation-click-area {
    display: block;
  }
}
