/*
Order review card

Markup:
<div class="order-review__order-card">
  <div class="order-review__order-card__image">
    <img src="https://via.placeholder.com/640x480/09f/fff/?text=img" />
  </div>
  <div class="order-review__order-card__content">
    <h2 class="title-s">Product Name</h2>
    <p>
      Wednesday, 16 december 2020,<br/>
      Timeslot: 15:30
    </p>
  </div>
</div>

Style guide: components.checkout.order-review-card
*/

.order-review__order-card {
  align-items: center;
  display: grid;
  grid-template-columns: 128px auto;
  grid-gap: 30px;
  margin-left: -$container-padding-mobile;
  margin-right: -$container-padding-mobile;

  @include tablet {
    grid-template-columns: 1fr 2fr;
    margin-top: $spacing-xl;
    margin-left: 0;
    margin-right: 0;
  }
}

.order-review__order-card__content {
  h2 {
    font-weight: 700;
    margin-bottom: $spacing-xs;
  }

  time {
    @extend small;

    display: block;

    line-height: 1.38;
  }
}

.order-review__order-card__image {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  width: 100%;

  img {
    display: block;
    object-fit: cover;
  }
}

.order-review__btn-edit {
  @extend small;
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;

  @include on-event {
    text-decoration: none;
  }
}

.order-review__payment-container {
  padding-bottom: $spacing-m;
}
