.submenu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a {
    margin: 0 $spacing-xs $spacing-xs 0;
    padding: 0 $spacing-s;
    border: 1px solid $background-dark;
    color: $text-color;
    font-size: 0.875em;

    @include tablet {
      margin: 0 $spacing-ms $spacing-ms 0;
      background: $background-dark;
      color: $background-dark-opposite;
    }
  }

  p {
    margin: 0 $spacing-ms $spacing-ms 0;

    @include mobile-only {
      margin: 0 $spacing-xs $spacing-xs 0;
      width: 100%;
    }
  }
}
