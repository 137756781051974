$category-width-mobile: 140px;
$category-width-desk: 140px;

.category-slider {
  .category {
    flex: 0 0 $category-width-mobile;

    &:last-child {
      @include mobile-only {
        flex: 0 0 #{$category-width-mobile + $container-padding-mobile};
      }

      @include tablet {
        flex: 0 0 #{$category-width-desk + $grid-gap};
      }
    }

    @include tablet {
      flex: 0 0 $category-width-desk;
    }
  }

  @include mobile-only {
    .button {
      display: flex;
    }
  }

  .category__content {
    align-items: center;
    text-align: center;
  }

  .image {
    padding: 0;
    max-width: 60px;
    height: 34px;

    img {
      object-fit: contain;
    }
  }
}
