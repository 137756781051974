// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

/*
Buttons

.button  
Wanneer een element zich als knop moet gedragen, maar er verder niet zo hoeft uit te zien

:hover - hover state
.is-disabled - disabled button

Markup:
<button class="button {{modifier_class}}">Klik hier</button>

Style guide: components.elements.buttons
*/

button {
  -webkit-appearance: none;
  background: none;
  box-shadow: none;
  border: none;

  &:focus {
    outline: none;
    opacity: 0.9;
  }
}

.button,
[class^="button--"] {
  cursor: pointer;
  @include flex-center;
  padding: $font-correction-button--pt $spacing-m $font-correction-button--pb;
  min-height: 42px;
  border-radius: 100px;
  font-size: 0.8em;
  font-weight: 700;
  -webkit-appearance: none;

  .fas,
  .fal,
  .far {
    margin: 0 8px 0 0;
  }

  &.is-disabled {
    cursor: default;
  }
}

/*
Button Primary

:hover - hover state
.is-disabled - disabled button

Markup:
<button class="button--primary {{modifier_class}}">Klik hier</button>

Style guide: components.buttons.button-primary
*/
.button--primary {
  background: $button-primary-background;
  color: $button-primary-color;
  padding-top: 0.25em;

  &.is-disabled {
    background-color: lighten($button-primary-background, 25%);
    color: rgba($button-primary-color, 0.5);
  }

  &:not(.is-disabled) {
    @include on-event {
      background: darken($button-primary-background, 5%);
      color: $button-primary-color;
      text-decoration: none;
    }
  }
}

/*
Button Secondary

:hover - hover state
.is-active - activated button
.is-disabled - disabled button

Markup:
<button class="button--secondary {{modifier_class}}">Klik hier</button>

Style guide: components.buttons.button-secondary
*/
.button--secondary {
  background: $button-secondary-background;
  color: $button-secondary-color;
  padding-top: 0.25em;

  &.is-active {
    background: $button-secondary-background;
    color: $button-secondary-color;
  }
}

.button--outline {
  border: 1px solid currentColor;
}

/*
Button Light

:hover - hover state
.is-disabled - disabled button

Markup:
<button class="button--light {{modifier_class}}">Klik hier</button>

Style guide: components.buttons.button-light
*/
.button--light {
  background: $button-light-background;
  color: $button-light-color;
}

/*
Button Loading

Markup:
<button class="button--primary button--loading">Klik hier</button>

Style guide: components.buttons.button-loading
*/
.button--loading {
  &:before {
    content: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><g fill="none" fill-rule="evenodd" stroke-width="3"><circle stroke="%23FFF" opacity=".5" cx="14" cy="14" r="12"/><path d="M22.53 22.441A11.962 11.962 0 0026 14c0-6.627-5.373-12-12-12" stroke="%23FF71EC" stroke-linecap="round"/></g></svg>');
    margin-right: $spacing-s;
    height: 20px;
    width: 20px;
    animation: spin 1.5s cubic-bezier(0.785, 0.135, 0.15, 0.86) infinite;
  }
}

/*
Link

:hover - hover state

Markup:
<a href="#" class="link">Klik hier</a>

Style guide: components.buttons.link
*/
.link {
  cursor: pointer;
  font-weight: 700;
  text-decoration: underline;
}
