.general-content {
  padding-top: #{$spacing-ml + $navigation-height--mobile};

  @include tablet {
    padding-top: #{$spacing-l + $navigation-height--desk};
  }

  .footer {
    margin-top: $spacing-ml;

    @include tablet {
      margin-top: $spacing-l;
    }
  }
}
