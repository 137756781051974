.ais-Pagination {
  margin: 16px 0;

  .ais-Pagination-list {
    list-style-type: none;
    display: flex;
    justify-content: center;
    padding: 0;
  }

  .ais-Pagination-item {
    margin: 8px;
  }

  .ais-Pagination-item--disabled {
    display: none;
  }

  .ais-Pagination-item--selected .ais-Pagination-link {
    color: $color-primary;
    font-weight: bold;
  }
}
