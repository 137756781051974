.block--usps {
  .block__content {
    grid-column: 2 / -2;

    @include mobile-only {
      flex-direction: column;
    }

    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
    }

    &.has-button {
      .redactor {
        grid-column: 1 / -1;
      }

      .usps {
        grid-column: 1 / 3;
        grid-row: 2 / 3;

        grid-template-columns: 1fr 1fr;
        margin-top: $spacing-m;
      }
    }
  }

  .redactor {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    font-size: 1.25em;
    align-self: center;
  }

  .button--secondary {
    margin-top: $spacing-m;
    max-width: 330px;

    @include tablet {
      grid-row: 2 / 3;
      grid-column: 3 / 4;
      align-self: center;
      margin-top: $spacing-m;
    }
  }

  .usps {
    grid-row: 1 / 2;
    grid-column: 2 / 4;

    @include tablet {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: $spacing-m;
    }
  }

  .usp {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 100%;

    @include mobile-only {
      margin-top: $spacing-s;
    }

    .fas {
      margin-right: $spacing-xs;
      color: $color-primary;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 $spacing-s 0 0;
    height: $spacing-l;
    min-height: $spacing-l;
    width: $spacing-l;
    min-width: $spacing-l;
    background: $background-dark;
    border-radius: 100%;

    img {
      max-width: 20px;
      max-height: 16px;
    }
  }
}
