/*
Checkout sticky footer 

Markup:
<div class="checkout-footer">
  <div class="checkout-footer__total-amount">
    3x
  </div>
  <div class="checkout-footer__total-price">
    Totaal &euro; 19.00
  </div>
  <div class="checkout-footer__status-text">
    Status Message
  </div>
  <div class="checkout-footer__cta">
    <button class="button--primary">
      Verder met bestellen
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
</div>

Style guide: components.checkout.sticky-footer
*/

$checkout-footer__padding-bottom--mobile: $spacing-ms;
$checkout-footer__padding-top--mobile: $spacing-ml;
$checkout-footer__padding-bottom--tablet: $spacing-ms;
$checkout-footer__padding-top--tablet: $checkout-footer__padding-bottom--tablet;

.checkout-footer {
  @include grid-container(
    $mobile-display: grid,
    $column-count: 5,
    $column-count--mobile: 2
  );

  background: $color-accent-soft;
  padding-bottom: $checkout-footer__padding-bottom--mobile;
  padding-top: $checkout-footer__padding-top--mobile;

  @include tablet {
    grid-template-columns: 0 auto 1fr auto auto 0;
    padding-bottom: $checkout-footer__padding-bottom--tablet;
    padding-top: $checkout-footer__padding-top--tablet;
  }
}

.checkout-footer__total-amount,
.checkout-footer__total-price,
.checkout-footer__status-text {
  @extend small;
  align-self: center;
}

.checkout-footer__total-amount {
  grid-column: 2 / 3;

  @include tablet {
    font-size: 14px;
    grid-column: 2 / 3;
  }
}

.checkout-footer__total-price {
  font-weight: 700;
  grid-column: 3 / 4;
  grid-row: 1 / 2;
  justify-self: right;

  @include tablet {
    justify-self: left;
  }
}

.checkout-footer__status-text {
  grid-column: 2 / -2;
  grid-row: 2 / 3;
  justify-self: right;

  @include tablet {
    grid-column: 2 / -2;
    grid-row: 2 / 3;
    justify-self: left;
  }

  @include tablet-landscape {
    grid-column: -4 / -3;
    grid-row: 1 / 2;
    justify-self: right;
  }
}

.checkout-footer__cta {
  grid-column: 2 / -2;
  grid-row: 3 / 4;
  padding-top: $checkout-footer__padding-bottom--mobile;

  i {
    padding-left: 1em;
  }

  button {
    width: 100%;
  }

  @include tablet {
    grid-column: -3 / -2;
    grid-row: 1 / 2;
    justify-self: end;
    padding-top: 0;
    width: 340px;
  }
}
