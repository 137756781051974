/*
Form

Style guide: layout.form
*/

input {
  border-radius: $border-radius;
  box-shadow: none;
  outline: none;
  background: $input-background-color;
  border: 1px solid $input-border-color;
  color: $input-color;

  -webkit-appearance: none;
  transition: all $form-transition-speed;

  &::placeholder {
    color: $input-placeholder-color;
    font-size: 0.9em;
  }

  &:focus {
    border-color: $input-focus-border-color;
  }
}

/*
Form

Markup:
<input type="text" placeholder="placeholder" name="text" />
<br />
<input type="search" placeholder="placeholder" name="search" />
<br />
<input type="email" placeholder="placeholder" name="email" />
<br />
<input type="password" placeholder="placeholder" name="password" />

Style guide: layout.form.text-input
*/

input[type="text"],
input[type="search"],
input[type="email"],
input[type="password"] {
  min-height: 48px;
  padding: 0 $spacing-ms;
  width: 100%;

  &.is-error {
    background-color: $input-error-background;
    border-color: $input-error-border;
  }
}

.input {
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    right: $spacing-m;
    transform: translateY(-50%);
  }

  input[type="search"]:focus ~ .icon,
  input[type="search"]:hover ~ .icon {
    display: none;
  }
}

// CHECKBOX STYLING
.checkbox {
  position: relative;
  display: inline-block;
  height: 24px;
  width: 24px;
  min-width: 24px;
  border-radius: $checkbox-border-radius;
  background: $checkbox-background;
  border: 1px solid $checkbox-border-color;

  &:hover {
    border-color: $input-focus-border-color;
  }

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
  }

  input:checked ~ .checkmark,
  &.selected:after {
    content: url('data:image/svg+xml, <svg width="19" height="15" viewBox="0 0 19 15" xmlns="http://www.w3.org/2000/svg"><path fill="#{ svg-color-string-modifier($input-color) }" d="M16.463 1.09L6.107 11.445 2.302 7.64a.472.472 0 00-.668 0L.521 8.754a.472.472 0 000 .668l5.252 5.252a.472.472 0 00.668 0L18.244 2.871a.472.472 0 000-.668L17.131 1.09a.472.472 0 00-.668 0z" fill-rule="nonzero"/></svg>');
    margin: 3px 2px;
    display: inline-block;
    height: 18px;
    width: 18px;
  }

  &.is-small {
    height: 17px;
    width: 17px;
    min-width: 17px;

    input:checked ~ .checkmark,
    &.selected:after {
      margin: 0;
      height: 12px;
      left: 0px;
      position: absolute;
      top: 3px;
      width: 15px;
    }
  }
}

// RADIO BUTTON STYLING
.radio {
  input[type="radio"] {
    display: none;
  }

  .checkmark,
  .checkmark:after {
    background-color: $input-radio-background;
    border-radius: 50%;
    display: inline-block;
    height: 1.5em;
    margin-left: 0.375em;
    margin-right: 0.75em;
    width: 1.5em;
  }

  input:checked ~ .checkmark,
  input:hover ~ .checkmark {
    border: 5px solid $input-radio-background;
    background-color: $input-radio-checked-background;
  }
}

// SELECT DROPDOWN STYLING

// TEXT INPUT STYLING

// SEARCH STYLING

// SUBMIT BTN STYLING

/*
Form row

Markup:
<div class="field">
  <label class="label">First name</label>
  <input type="text" />
  <p class="help is-error">This email is invalid</p>
</div>
<div class="field">
  <label class="label">Last name</label>
  <input type="text" />
</div>
<div class="field field--checkbox">
  <div class="checkbox">
    <input type="checkbox" />
    <div class="checkmark"></div>
  </div>
  <label class="label">Yes or no?</label>
</div>
<div class="field is-button-row">
  <button class="button--primary" type="submit">Submit</button>
</div>
<div class="form-footer">
  <div class="form-footer__link-wrap">
    <a class="form-footer__link" href="#">Wachtwoord vergeten?</a>
  </div>
  <div class="form-footer__link-wrap">
    <a class="form-footer__link" href="#">Ik heb geen account</a>
  </div>
</div>

Style guide: layout.form.row
*/
.field {
  padding-bottom: 0.75em;

  &.is-button-row {
    padding-top: 1.125em;
  }

  & > .label {
    @extend small;
  }

  & > .help {
    @extend small;

    &.is-error {
      color: $color-red;
    }
  }
}

.field--checkbox {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 0.5em;
  align-items: flex-start;

  & > .label {
    line-height: 1.38;
  }
}

.form-footer__link-wrap {
  padding-bottom: 0.5em;
}

.form-footer__link {
  cursor: pointer;
  font-size: 13px;
}

.form {
  .succes-message,
  .error-message {
    border-radius: $border-radius;
    padding: $spacing-s;
    background: $color-green;
    color: $color-white;
  }

  .error-message {
    background: $color-red;
  }

  .field--hidden {
    display: none;
  }
}
