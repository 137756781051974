.product {
  overflow: hidden;

  @include tablet {
    padding-top: $spacing-l;
    padding-bottom: $spacing-xxxl;
  }

  .image {
    margin-bottom: $spacing-l;

    @include mobile-only {
      margin: 0 #{-$container-padding-mobile} $spacing-m;
      max-width: calc(100% + #{$container-padding-mobile * 2});
    }

    @include tablet {
      &.tablet {
        display: flex;
      }
    }
  }

  .intro {
    margin-bottom: $spacing-s;
  }

  .price {
    display: flex;
    margin-top: $spacing-m;

    @include mobile-only {
      flex-direction: column;
      justify-content: flex-end;
      position: absolute;
      top: -$spacing-m;
      left: -$container-padding-mobile;
      margin: 0;
      padding: $spacing-ms;
      transform: translateY(-100%);
      background: $color-white;
    }

    .value {
      @include tablet {
        margin-right: $spacing-s;
        font-size: 1.25em;
        font-weight: 700;
      }
    }
  }

  .metadata {
    margin-top: $spacing-s;
    font-size: $font-size-small;

    @include mobile-only {
      display: none;
    }

    .icon {
      margin-right: $spacing-s;
    }
  }

  @include tablet {
    > .discount-label {
      display: none;
    }
  }

  .metadata__item:not(:first-child) {
    margin-top: $spacing-s;
  }

  [class^="button"] {
    @include tablet {
      margin-top: $spacing-m;
    }
  }

  @include mobile-only {
    .add-to-cart {
      margin-top: $spacing-l;
    }

    .add-to-cart:not(.stick) {
      position: fixed;
      z-index: 6;
      bottom: 0;
      left: 0;
      padding: $container-padding-mobile;
      width: 100vw;
      background: $color-white;
    }
  }

  .social-share {
    margin-top: $spacing-l;
  }
}

.product__sidebar {
  grid-column: 8 / 13;
  grid-row: 1 / 2;
  position: sticky;
  top: 20px;
  height: fit-content;
  overflow-wrap: break-word;
  transition: top 0.25s;

  @include mobile-only {
    position: relative;
    top: 0;
    padding: 0 0 $spacing-m;
  }
}

.product__main {
  position: relative;
  overflow: hidden;
  grid-column: 3 / 8;
  grid-row: 1 / 2;

  @include mobile-only {
    padding: 0 0 $spacing-l;

    .discount-label {
      display: none;
    }
  }
}

.product__social {
  grid-column: 1 / -1;
  grid-row: 2 / 3;
}

.product-venue {
  padding-top: $spacing-xxl;
  padding-bottom: $spacing-xxl;
  background: $background-light;

  @include tablet {
    grid-row-gap: $spacing-xxl;
  }

  h4:not(:first-child) {
    margin-top: $spacing-m;
  }

  p {
    margin-top: $spacing-xxs;
    white-space: pre-line;
  }

  > *:first-child {
    @include tablet {
      grid-column: 3 / 8;
    }
  }

  > *:nth-child(2) {
    @include tablet {
      grid-column: 8 / -3;
    }

    @include mobile-only {
      margin-top: $spacing-m;
    }
  }
}
