.article {
  .grid-container {
    @include tablet {
      margin-top: $spacing-xl;
    }
  }

  .article-image {
    position: relative;
    grid-column: 3 / -3;
    grid-row: 1 / 3;
    position: relative;
    margin-bottom: $spacing-xl;
    line-height: 0;

    @include mobile-only {
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      height: 100vw;
      width: 100vw;
    }

    &:before {
      content: "";
      @include imageGradientOverlay;
    }

    .image,
    .image img {
      height: auto;
      width: 100%;
    }
  }

  .article-header,
  .article-info,
  .article-intro {
    grid-column: 5 / -5;
  }

  .social-share {
    grid-column: 1 / -1;
  }

  .article-copyright {
    grid-column: 3 / -3;
    margin: -#{$spacing-xl} 0 0;

    @include mobile-only {
      margin: -28px 0 20px;
    }

    p {
      font-size: 0.75em;
      opacity: 0.4;
      text-transform: uppercase;
    }
  }

  .article-header {
    grid-row: 2 / 3;
    position: relative;
    z-index: 1;

    @include mobile-only {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: $spacing-ml;
      padding-bottom: $spacing-ml;
      width: calc(100vw - #{$container-padding-mobile * 2});
      height: 100vw;
    }

    @include tablet {
      padding-bottom: #{$spacing-xl * 2};
    }

    * {
      color: $color-white;
    }
  }

  .article-info {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-ml;
  }

  .article-content {
    margin-bottom: $spacing-xl;
  }

  .date,
  .reading-time {
    display: inline-block;
    margin: $spacing-xs $spacing-s 0 0;
    font-size: $font-size-small;

    .icon {
      margin-right: 4px;
    }
  }

  .user-image {
    display: inline-block;
    margin-right: $spacing-m;
    height: $spacing-xxl;
    width: $spacing-xxl;
    object-fit: cover;
    border-radius: 100%;
  }

  .article-intro {
    font-size: 1.25em;
    font-weight: 700;
    line-height: 140%;

    @include mobile-only {
      margin-bottom: $spacing-xl;
    }
  }

  .redactor {
    margin-bottom: $spacing-xl;
  }

  iframe {
    max-width: 100%;
  }
}
