.budget-info {
  order: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 8px 0 16px;
  padding: 4px 24px 0;
  border-radius: 28px;
  height: 44px;
  background: #eee;

  &.loading {
    .budget {
      opacity: 0.2;
    }
  }

  .title {
    font-size: 10px;
    line-height: 10px;
    opacity: 0.5;
  }

  @include until-tablet-landscape {
    justify-self: right;
    margin: 0 12px 0 0;
    padding: 4px 16px 0;
    background: rgba(255, 255, 255, 0.2);
  }
}

@include until-tablet-landscape {
  body:not(.homepage) {
    .budget-info {
      grid-column: 1 / 4;
      margin: 0 16px 16px;
      padding: 4px 16px 0;
      width: calc(100% - 32px);
    }
  }

  body.search-open {
    .budget-info {
      margin-bottom: 0;
    }
  }
}
