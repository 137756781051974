/*
V-Calendar

Styling to overwrite default v-calendar styling

Don't style '.vc-grid..' classes. These are the grid/layout-system that v-calendar uses.
*/

.vc-pinkly {
  --pinkly-100: #fff5f7;
  --pinkly-200: #fed7e2;
  --pinkly-300: #fbb6ce;
  --pinkly-400: #f687b3;
  --pinkly-500: #ed64a6;
  --pinkly-600: #d53f8c;
  --pinkly-700: #b83280;
  --pinkly-800: #97266d;
  --pinkly-900: #702459;
}

.vc-pinkly {
  --font-bold: 900;
}

.vc-container.vc-pinkly {
  border: none;
  font-family: $text-font-stack;

  .vc-header {
    padding-bottom: 3em;
    padding-top: 1.25em;

    .vc-title {
      font-weight: 500;
    }
  }

  .vc-weeks,
  .vc-weekday,
  .vc-day {
    border: 1px solid $background-light;
  }

  .vc-weeks {
    border-bottom: none;
    border-right: none;
    padding: 0;
    margin: 0 6px;
  }

  .vc-weekday,
  .vc-day {
    border-left: none;
    border-top: none;
  }

  .vc-weekday {
    background: $background-light;
    color: $background-dark;
  }

  .vc-day {
    // grey circle on hover
    .vc-day-content {
      @include on-event {
        background: none;
      }
    }

    .vc-highlights {
      background: $color-accent-hard;
      color: $color-white;

      .vc-highlight {
        display: none;
      }
    }
  }

  .vc-day:not(.is-not-in-month) {
    @include on-event {
      background: $color-accent-hard;
      box-shadow: $box-shadow-inset;
      color: $color-white;
      cursor: pointer;
      outline: none;
      transition: background-color $general-transition-speed,
        box-shadow $general-transition-speed;
    }
  }

  .vc-arrows-container {
    .vc-arrow {
      background: $background-dark;
      border-radius: 50%;
      color: $color-white;
      height: 3em;
      width: 3em;
      transition: opacity $general-transition-speed;

      &:first-child {
        margin-right: $spacing-xs;
      }

      &.is-disabled {
        background: $background-light;
        opacity: 1;
      }

      @include on-event {
        opacity: 0.8;
      }
    }
  }
}
