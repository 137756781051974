.tile {
  align-self: start;
  position: relative;
  overflow: hidden;
  padding: 100% 0 0;
  width: 100%;
  background: #aaa;

  picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    object-fit: cover;
  }

  &:not(.loading):before {
    content: "";
    @include imageGradientOverlay;
  }

  &.loading {
    @include skeleton-loader;
  }
}

.tile__content {
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: $grid-gap;
  color: $color-white;

  .date,
  .city {
    margin-top: 0.25em;
  }

  .icon {
    margin: 0 $spacing-xs 0 0;
    font-size: 14px;
  }

  p {
    font-size: $font-size-small;
  }
}

.page-tile {
  background: white;
  border-top: 1px solid $text-color;
  padding: $spacing-m 0;
  color: $text-color;

  &:before {
    display: none;
  }

  .uri {
    margin: 4px 0 0;
    opacity: 0.5;
    font-size: 12px;
  }

  .arrow {
    position: absolute;
    top: 50%;
    right: $spacing-s;
    transform: translateY(-50%);
  }
}
