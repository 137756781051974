/*
Icons

Markup:
<i class="icon {{modifier_class}}"></i>

Style guide: components.elements.icons
*/

.icon,
[class^="icon--"] {
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 1em;
  width: 1em;
}

.icon--ideal {
  background-image: url("/assets/ideal-logo.svg");
  height: calc(17/16) * 1em;
  transform: translateY(calc(2/16) * 1em);
  width: 1.25em;

  &:last-child {
    margin-left: 0.4em;
  }
}

.icon--ideal-bank {
  height: 1.5em;
  width: 2em;
}
