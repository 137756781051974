.discount-label {
  top: -72px;
  left: auto;
  right: -60px;
  justify-content: center;
  padding: 72px 60px $spacing-ms $spacing-ms;
  width: 160px;
  height: 160px;
  border-radius: 100%;
  background: $label-background;

  text-align: center;
  font-size: 1em;
  font-family: $text-font-stack;
  line-height: 110%;

  &:after {
    display: none;
  }
}
