.general-header {
  .title,
  .intro,
  .submenu {
    grid-column: 3 / -3;
  }

  .intro {
    margin-top: $spacing-ml;
    max-width: 900px;
    font-weight: 600;
  }

  .submenu {
    margin-top: $spacing-m;
  }

  .image {
    grid-column: 2 / -2;
    margin-top: $spacing-xxxl;

    @include mobile-only {
      margin: $spacing-ml #{-$grid-gap--mobile} 0;
    }
  }
}
