/*
Checkout timeslot selector

Markup:
<div class="timeslot-selector">
  <button class="timeslot-selector__timeslot">
    timeslot
  </button>
  <button class="timeslot-selector__timeslot">
    timeslot
  </button>
  <button class="timeslot-selector__timeslot">
    timeslot
  </button>
  <button class="timeslot-selector__timeslot" disabled>
    timeslot
  </button>
  <button class="timeslot-selector__timeslot">
    timeslot
  </button>
  <button class="timeslot-selector__timeslot is-active">
    timeslot
  </button>
  <button class="timeslot-selector__timeslot" disabled>
    timeslot
  </button>
</div>

Style guide: components.checkout.timeslot-selector
*/

/*
Timeslot selector - timeslot

:hover - hover state
.is-active - active state
.is-disabled - disabled state

Markup:
<button class="timeslot-selector__timeslot {{modifier_class}}">
  timeslot
</button>

Style guide: components.checkout.timeslot-selector.timeslot
*/

.timeslot-selector,
.timeslot-selector__timeslot {
  border: 1px solid $background-light;
}

.timeslot-selector {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-right: none;
  border-bottom: none;

  @include tablet {
    grid-template-columns: repeat(4, 1fr);
  }
}

.timeslot-selector__timeslot {
  border-top: none;
  border-left: none;
  cursor: pointer;
  font-size: $font-size-small;
  font-weight: 700;
  padding: 1em 1.75em;

  &.is-active {
    background: $color-accent-hard;
    color: $color-white;
  }

  &:disabled,
  &.is-disabled {
    background: $input-disabled-background;
    color: rgba(0, 0, 0, 0.41);
    cursor: default;
  }

  @include on-event {
    background: $color-accent-hard;
    box-shadow: $box-shadow-inset;
    color: $color-white;
    outline: none;
    transition: background-color $general-transition-speed,
      box-shadow $general-transition-speed;
  }
}
