.product {
  padding-bottom: 0;
}

.product--expired {
  .discount-label {
    background: #ccc;
    color: #999;
  }
}

.product__sidebar {
  @include tablet {
    padding-bottom: $spacing-xxxl;
  }
}
