.navigation-search-mobile {
  width: 100%;
  background: $header-background-color;
  transform: translateY(-100%);
  transition: transform $general-transition-speed;

  input {
    background: none;
    border: none;
  }

  @include tablet-landscape {
    display: none;
  }
}

.navigation--search-open {
  @include until-tablet-landscape {
    height: #{$navigation-height--mobile + $navigation-search-height};
  }

  .navigation-search-mobile {
    transform: translateY(0);
  }
}

//Other items affected by navigation search
body.search-open {
  @include until-tablet-landscape {
    padding-top: #{$navigation-height--mobile + $navigation-search-height};
  }

  .navigation-mobile {
    top: #{$navigation-height--mobile + $navigation-search-height};
    height: calc(
      100vh - #{$navigation-height--mobile + $navigation-search-height}
    );
  }
}

body.search-transition {
  transition: padding $general-transition-speed;
}
