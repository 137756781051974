// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/*
Base

The `base/` folder holds what we might call the boilerplate code for the project. In there, you might find some typographic rules, and probably a stylesheet (that I’m used to calling `_base.scss`), defining some standard styles for commonly used HTML elements.

Style guide: 2-base
*/

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

body {
  &.scroll-lock {
    position: relative;
    overflow: hidden;
  }
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/*
Links

Basic styles for links

:hover - hover state

Markup:
<a href="#" class="{{modifier_class}}">Fontfamily weight ##/## (size/line-height)</a>

Style guide: 2-base.typography.links
 */

a {
  color: $link-color;
  text-decoration: none;

  @include on-event {
    color: $color-primary;
    text-decoration: underline;
  }
}

img {
  max-width: 100%;
}
