// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
$footer-distance: 40px;

.footer__form {
  input {
    border-radius: 100px;

    &:focus {
      border-color: currentColor;
    }
  }

  .title-m--alt {
    @include tablet {
      font-size: 2em;
    }
  }
}

.footer__support {
  .button--secondary {
    background: $background-dark;
    color: $background-dark-opposite;
  }

  .redactor strong {
    color: currentColor;
  }
}

.footer__copyright {
  display: none;
}

.footer__logo {
  display: block;
  padding: calc($footer-distance / 2) 0;

  @include tablet {
    grid-column: 2 / 7;
    grid-row: 5 / 6;
  }

  img {
    max-height: 38px;
  }
}

.footer__divider {
  @include tablet {
    grid-column: 1 / -1;
  }
}
