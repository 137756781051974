.search-nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto $spacing-xxxl;

  @include mobile-only {
    margin: $spacing-xl auto 0;
  }

  .title {
    flex: 0 0 100%;
    margin: 0 0 $spacing-s;
    text-align: center;
    font-size: 2em;
  }
}

.search-nav__item {
  cursor: pointer;
  margin: 0 $spacing-s;
  text-decoration: underline;

  &:hover {
    color: $color-primary;
  }
}
