.search-header {
  position: relative;
  display: flex;
  padding: $spacing-m 0;
  min-height: 180px;
  background: $background-light;

  @include until-tablet-landscape {
    display: none;
  }

  //to prevent white space on scrollbounce
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: -$navigation-height--desk;
    height: $navigation-height--desk;
    width: 100%;
    background: $background-light;
  }

  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  form {
    width: 100%;
    max-width: 618px;

    input {
      padding: 0 $spacing-m;
    }
  }
}
