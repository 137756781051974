.block--faq {
  @include mobile-only {
    .grid-container {
      padding: 0;
    }
  }

  .block__content {
    grid-column: 3 / -5;
  }

  .title-l {
    margin-bottom: $spacing-ms;

    @include mobile-only {
      padding-left: $container-padding-mobile;
    }
  }

  .faq-item.open {
    .question {
      border-bottom: none;
    }

    .icon {
      transform: rotate(180deg);
    }

    .answer {
      display: block;
    }
  }

  .question {
    cursor: pointer;
    display: flex;
    border-bottom: 1px solid $color-accent-soft;
    padding: $spacing-ms 0;

    @include mobile-only {
      padding-left: $container-padding-mobile;
      padding-right: $container-padding-mobile;
      font-size: 1em;
    }

    .icon {
      margin: 0 0 auto auto;
      transition: transform 0.2s;
    }
  }

  .answer {
    display: none;

    @include mobile-only {
      padding: $spacing-ms $container-padding-mobile;
      background: $background-light;
    }
  }
}
