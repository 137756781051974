/*
Checkout Header

Markup:
<header class="checkout-header grid-container">
  <div class="checkout-header__logo">
    <img src="https://via.placeholder.com/154x16/09f/fff/?text=logo" />
  </div>
  <div class="checkout-header__title">
    <h1>Checkout Header Title 24/.92</h1>
  </div>
  <div class="checkout-header__info">
    <p class="">Extra info regarding checkout</p>
  </div>
</header>

Style guide: components.checkout.header
*/

.checkout-header {
  background: $background-lightest;
  padding-bottom: 28px;
  padding-top: $spacing-ml;
  position: relative;

  &.is-error {
    color: $danger-text-color;
  }

  @include tablet {
    padding-top: $spacing-xxl;
  }
}

.checkout-header__logo {
  grid-column: 2 / -2;
  height: 1.5em;
  text-align: center;

  @include tablet {
    height: 1.25em;
    margin-bottom: $spacing-ml;
    text-align: left;
  }

  img {
    height: 100%;
  }
}

.checkout-header__title {
  display: none;

  grid-column: 2 / -2;

  @include tablet {
    display: block;
  }

  h1 {
    font-size: 1.125em;
    font-weight: 500;
    line-height: 1.22;
    text-align: center;

    @include tablet {
      font-size: 1.5em;
      font-weight: 500;
      line-height: 0.92;
      text-align: left;
    }
  }

  @include tablet-landscape {
    grid-column: 2 / 9;
  }
}

.checkout-header__info {
  color: $info-text-color;
  display: none;
  grid-column: 9 / -2;
  text-align: right;

  @include tablet-landscape {
    display: block;
  }
}

.checkout-header__progress-bar {
  bottom: 0;
  background-color: $background-dim;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;

  .checkout-header__progress {
    background-color: $color-accent-hard;
    display: block;
    height: 100%;
    width: 0;
    transition: width $modal-transition-speed;
  }
}

.is-error {
  .checkout-header__progress {
    background-color: $danger-text-color;
  }
}
