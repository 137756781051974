.navigation-mobile {
  .navigation__menu--main {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  a,
  .button--solid {
    border: none;
    padding: $spacing-s $spacing-m;
    font-size: 1em;
    font-weight: bold;
  }

  .button--solid:first-child,
  .button--logout {
    border-radius: 0;
    border-bottom: 1px solid rgba($text-color, 0.3);
  }

  .navigation__menu--secondary,
  .navigation__categories {
    .regular {
      border-bottom: 1px solid rgba($text-color, 0.3);
    }
  }
}
