.category-label {
  margin-right: $spacing-s;
  margin-bottom: 0.33em;
  display: inline-block;
  font-size: $font-size-small;

  &:first-child:before {
    content: "";
    display: inline-block;
    margin: 4px $spacing-xs -2px 0;
    height: 12px;
    width: 2px;
    background: $color-primary;
    border-radius: 2px;
  }

  &:not(:first-child):before {
    content: "|";
    margin: 0 $spacing-xs 0 0;
    opacity: 0.1;
  }
}
