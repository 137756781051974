// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

// Style settings
$box-shadow: 0 0 15px rgba(#000000, 0.2);
$border-radius: 3px;

$text-font-stack: "Roboto", Helvetica, sans-serif;
$title-font-stack: "Formula", Helvetica, sans-serif;

$font-correction-button--pt: 4px;
$font-correction-button--pb: 0;

$navigation-height--desk: 90px;
