.article {
  .article-info span {
    border-bottom: 1px solid $color-primary;
  }

  .article-intro {
    font-size: 1.1em;
    line-height: 150%;
  }

  .category-label {
    margin-bottom: 0.75em;
  }

  .social-share .title,
  .social-share .social-share-icons {
    grid-column: 5 / -5;
  }
}
