.social-share {
  .title,
  .social-share-icons {
    grid-column: 3 / -3;
  }
}

.social-share-icons {
  display: flex;
  margin-top: $spacing-s;

  .icon {
    @include flex-center;
    margin-right: $spacing-s;
    width: 42px;
    height: 42px;
    border-radius: 24px;
    background: $background-dark;
    color: $background-dark-opposite;
    font-size: 18px;
  }
}
