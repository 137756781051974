// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------

.button,
[class^="button--"] {
  border: 1px solid currentColor;
}

.button--primary {
  background: $color-primary;
  color: $color-primary-negative;
  border: 1px solid currentColor;

  &:hover {
    color: $color-primary-negative;
  }
}

.button--light,
.button--secondary {
  background: $color-white;
  color: $text-color;
}

.button--disabled {
  cursor: default;
  background: #ccc;
  color: #999;
  border-color: transparent;
}
