.product-list {
  grid-column: 5 / 13;
  display: flex;
  flex-direction: column;

  @include tablet {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: $grid-gap;
  }

  @include widescreen {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @include mobile-only {
    .tile {
      margin-top: 20px;
      //width: calc(100% + #{$container-padding-mobile * 2});
    }
  }
}

.product-list__empty {
  grid-column: 1 / -1;
  @include flex-center;
  flex-direction: column;
  padding: $spacing-l 0;
  min-height: 300px;

  @include tablet {
    padding: 0 0 $spacing-l;
  }

  i {
    font-size: 60px;
    color: $input-placeholder-color;
  }

  .title-m--alt {
    border-top: 1px solid $color-accent-soft;
    margin: $spacing-l 0 0;
    padding: $spacing-l 0 0;
    font-size: 2em;
  }

  p {
    margin: $spacing-m 0 0;
    text-align: center;
  }
}
