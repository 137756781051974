// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
$footer-distance: 40px;
$footer-distance-mobile: 20px;

.footer {
  margin-top: auto;

  @include tablet {
    background: $background-lightest;
  }

  .grid-container:before {
    content: "";
    grid-column: 1 / -1;
    grid-row: 5 / 6;
    background: $color-white;
  }

  .freeform-form-has-errors {
    display: none; //Hide general error message, since there's only one field
  }
}

a {
  color: $text-color;
}

.footer__form {
  margin: 0 0 $footer-distance;
  padding: $footer-distance-mobile 0;
  background: $background-dark;
  color: $background-dark-opposite;

  @include tablet {
    padding: calc($footer-distance / 2) 0;
  }

  .title-m--alt {
    @include tablet {
      white-space: pre;
    }
  }

  input:not([type="checkbox"]) {
    background: $input-dark-background;
    color: $input-dark-color;
    border-color: $input-dark-border;
    min-width: 300px;

    &:focus {
      border-color: $input-dark-border;
    }

    &::placeholder {
      color: $input-dark-placeholder;
    }
  }

  .checkbox {
    margin-right: $spacing-s;
  }

  .button:before {
    content: "\f0e0";
    @include font-awesome;
  }

  .container {
    display: grid;
    column-gap: $grid-gap;
    row-gap: 14px;

    @include tablet {
      grid-template-columns: 1fr 1fr;
      align-items: center;
      justify-content: space-between;

      .footer__form__column:last-child {
        grid-column: 1 / -1;
      }
    }

    @include tablet-landscape {
      grid-template-columns: 1fr 1fr auto;

      .footer__form__column:last-child {
        grid-column: auto;
      }
    }
  }

  .footer__form__title {
    font-size: 1.25em;
    font-weight: 500;
    margin-bottom: 0.45em;
  }

  .button {
    width: 100%;
  }

  .errors {
    @extend small;
    list-style: none;
    color: $danger-text-color;
  }

  .field label {
    display: flex;
  }
}

.footer__support {
  @include mobile-only {
    padding: 0 0 $footer-distance-mobile;
  }

  @include tablet {
    grid-column: 2 / 7;
    grid-row: 1 / 3;
  }

  .button--primary {
    margin: calc($footer-distance / 2) 0 0;
    width: fit-content;
  }

  .button--secondary {
    width: fit-content;
  }

  .redactor {
    margin: 0 0 $spacing-m;
    font-size: 1.25em;

    strong {
      color: $color-primary;
    }
  }
}

.footer__social {
  @include mobile-only {
    display: flex;
    justify-content: space-around;
    margin: 0 -#{$container-padding-mobile};
    padding: $container-padding-mobile 0;
    background: $background-dark;
  }

  @include tablet {
    grid-column: 8 / 14;
    grid-row: 1 / 2;
    text-align: right;
  }

  a {
    font-size: 24px;

    @include mobile-only {
      color: rgba($background-dark-opposite, 0.5);
    }

    @include tablet {
      margin: 0 0 0 $footer-distance;
    }
  }
}

.footer__contact {
  @include mobile-only {
    display: none;
  }

  @include tablet {
    grid-column: 8 / 14;
    grid-row: 2 / 3;
    text-align: right;
    padding-top: $spacing-m;
  }
}

.footer__divider {
  @include mobile-only {
    display: none;
  }

  @include tablet {
    grid-column: 2 / -2;
    grid-row: 3 / 4;
    margin: $footer-distance * 2 0 $footer-distance;
    height: 1px;
    background: $color-light-accent;
  }
}

.footer__account {
  @include mobile-only {
    margin: 0 #{-$container-padding-mobile};
    padding: $footer-distance-mobile 0;
  }

  @include tablet {
    grid-column: 2 / 7;
    grid-row: 4 / 5;
    padding: 0 0 $footer-distance;
  }

  a {
    display: flex;
    align-items: center;

    @include mobile-only {
      padding-left: $container-padding-mobile;
      padding-right: $container-padding-mobile;
      font-size: 0.825em;
    }

    i {
      margin-right: 8px;
      font-size: 24px;
    }
  }
}

.footer__content {
  @include mobile-only {
    display: flex;
    flex-direction: column;
    margin: 0 #{-$container-padding-mobile};
    padding: $footer-distance-mobile 0 0;
  }

  @include tablet {
    grid-column: 8 / 14;
    grid-row: 4 / 5;
    padding: 0 0 $footer-distance;
    text-align: right;
  }
  a {
    @include mobile-only {
      padding: $footer-distance-mobile $container-padding-mobile;
      border-bottom: 1px solid $color-light-accent;
      font-size: 0.825em;
    }

    @include tablet {
      margin: 0 0 0 calc($footer-distance / 2);
    }
  }
}

.footer__copyright {
  @include mobile-only {
    display: none;
  }

  @include tablet {
    padding: calc($footer-distance / 2) 0;
    grid-column: 2 / 7;
    grid-row: 5 / 6;
  }
}

.footer__legal {
  @include mobile-only {
    display: flex;
    flex-wrap: wrap;
    padding: $footer-distance-mobile 0;
  }

  @include tablet {
    padding: calc($footer-distance / 2) 0;
    grid-column: 8 / 14;
    grid-row: 5 / 6;
    text-align: right;
  }

  a {
    @include mobile-only {
      margin: 0 $spacing-ml 0 0;
      font-size: 0.7em;
      text-decoration: underline;
    }

    @include tablet {
      margin: 0 0 0 calc($footer-distance / 2);
    }
  }
}

.footer__logo {
  display: none;
}
