.navigation {
  .logo img {
    max-height: 32px;
  }

  &:not(.navigation--top-position) {
    background: $background-lightest;
  }

  @include until-tablet-landscape {
    .container {
      background: $background-dark;
      color: $background-dark-opposite;
    }

    .nav-button--switch,
    .nav-button--search {
      color: $background-dark-opposite;
    }

    input,
    input::placeholder {
      color: $background-dark-opposite;
    }

    .logo {
      display: none;
    }

    .logo--inverted {
      display: flex;

      img {
        max-height: $spacing-m;
      }
    }
  }
}

.homepage {
  .navigation__menu--main .regular {
    color: $color-white;
  }
}
