.social-share {
  @include mobile-only {
    padding: 0;
  }

  @include tablet {
    &:before,
    &:after {
      content: "";
      grid-column: 1 / -1;
      grid-row: 2 / 3;
      margin: $spacing-s 0 0;
      height: 1px;
      width: 100%;
      background: $border-color;
    }

    &:after {
      grid-row: 4 / 5;
      margin: 0;
    }
  }
}

.social-share-icons {
  margin: 0;
  grid-row: 3 / 4;

  @include mobile-only {
    margin: $spacing-xs -#{$container-padding-mobile} 0;
    padding: 0 $container-padding-mobile;
    border-top: 1px solid $border-color;
    border-bottom: 1px solid $border-color;
  }

  a {
    border-right: 1px solid $border-color;
    &:first-child {
      border-left: 1px solid $border-color;
    }
  }

  .icon {
    margin: 0;
    height: 60px;
    width: 60px;
    border-radius: 0;
    background: none;
    color: $text-color;
  }
}
