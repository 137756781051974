.block--featured {
  .block__content {
    grid-column: 2 / -2;
  }
}

.featured-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: $spacing-ms;

  @include mobile-only {
    margin-left: -$container-padding-mobile;
    margin-right: -$container-padding-mobile;
  }

  @include tablet {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: $grid-gap;
  }

  @include desktop {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .load-more {
    grid-column: 1 / -1;
    margin: 0 auto;
    width: 250px;
    outline: none;
  }

  .tile {
    @include tablet-only {
      &:nth-child(6n + 1),
      &:nth-child(6n + 5) {
        grid-column: span 2;
        grid-row: span 2;

        .title-m {
          font-size: 2.5em;
        }
      }
    }

    @include desktop {
      &:nth-child(11n + 1) {
        grid-column: span 2;
        grid-row: span 2;

        .title-m {
          font-size: 2.5em;
        }
      }
    }
  }
}
