.account-button.has-account-button {
  @include until-tablet-landscape {
    width: 100%;
  }

  @include tablet-landscape {
    position: relative;
    margin: 0 0 0 16px;

    .button,
    [class^="button--"],
    .regular {
      margin: 0;
    }

    &:hover {
      .button--logout {
        display: flex;
        top: calc(100%);
      }
    }

    .button--logout {
      display: none;
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      white-space: nowrap;
      border: none;
    }
  }
}
