.checkout-voucher__picture {
  grid-column: 6 / -6;
  position: relative;
  overflow: hidden;
  margin: $spacing-l 0;

  @include mobile-only {
    margin: 0 #{-$container-padding-mobile} $spacing-l;
  }
}

.checkout-voucher__main {
  grid-column: 5 / -5;
  margin-bottom: $spacing-xxl;
  text-align: center;

  .title {
    font-size: 2.5em;

    span {
      color: $color-primary;
    }
  }
}

.voucher-code {
  margin: $spacing-l auto;
}

.voucher-code__code {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto $spacing-s;
  padding: 4px $spacing-xxl 0;
  height: 80px;
  border-radius: 45px;
  background: $label-background;
  font-size: 3em;

  @include tablet {
    max-width: fit-content;
  }

  .icon {
    position: absolute;
    top: 50%;
    right: $spacing-ms;
    transform: translateY(-50%);
    font-size: 18px;
  }

  &.hover {
    color: rgba($text-color, 0.08);
  }

  .hover-text {
    position: absolute;
    font-size: 0.5em;
    color: $text-color;
  }
}

.voucher-code__disclaimer {
  opacity: 0.4;
}

.voucher-code__confirmation-text {
  margin-top: $spacing-s;
}
