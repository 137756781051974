.notice-pane {
  min-height: 360px;
  margin: 0 auto;
  max-width: calc($grid-width / 3);
  padding: $spacing-l 0;
  text-align: center;

  p {
    margin-top: $spacing-s;
  }

  .button--primary {
    margin-top: $spacing-m;
    width: 100%;
  }
}
