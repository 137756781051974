/*
Checkbox collapse

Markup:
  <div class="checkbox-collapse">
    <div>
      <div class="checkbox selected" ></div>
    </div>
    <div>
      <div class="checkbox-collapse__title">Macaroon caramels tootsie roll</div>
      <div class="checkbox-collapse__text">
        Jelly beans croissant gingerbread chocolate cake. Marzipan chocolate 
        cake marshmallow. Macaroon caramels tootsie roll. I love cake jelly.
      </div>
    </div>
  </div>

Style guide: components.elements.checkbox-collapse
*/

.checkbox-collapse {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: $spacing-s;
  padding-bottom: $spacing-ml;
}

.checkbox-collapse__title,
.checkbox-collapse__text {
  @extend small;
}

.checkbox-collapse__title {
  text-decoration: underline;
  cursor: pointer;
}

.checkbox-collapse__text {
  transition: max-height $general-transition-speed,
    opacity $form-transition-speed;

  &.is-collapsed {
    max-height: 0;
    opacity: 0;
  }
}
