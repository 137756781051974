/*
Checkout numbered section

Section used in checkout flow step 1

Markup:
<div class="checkout-section" style="border: 1px solid grey">
  <h3 class="title-m">
    <span class="checkout-section__number">1</span>
    Checkout Section Title
  </h3>
  <div class="checkout-section__content">
    Candy canes cookie lollipop. Tiramisu sweet roll cheesecake gummi bears tootsie roll. Marshmallow sweet roll croissant soufflé pie topping.
  </div>
</div>
<div class="checkout-section" style="border: 1px solid grey">
  <h3 class="title-m">
    <span class="checkout-section__number">2</span>
    Checkout Section Title
  </h3>
  <div class="checkout-section__content">
    Lollipop pastry chocolate gummies carrot cake sweet. Cake soufflé jujubes biscuit macaroon marzipan. Cake soufflé chupa chups. Tiramisu bear claw halvah candy cookie cupcake.
  </div>
</div>

Style guide: components.checkout.numbered-section
*/

.checkout-section {
  padding-bottom: 22px;
  padding-top: 22px;

  @include tablet {
    padding-bottom: 35px;
    padding-top: 35px;
  }
}

.checkout-section__number {
  background: $background-light;
  border-radius: 50%;
  display: inline-block;
  font-size: 0.9em;
  height: 1.6667em;
  line-height: 1.6667;
  text-align: center;
  width: 1.6667em;
}

.checkout-section__content {
  padding-top: 32px;

  @include tablet {
    padding-top: 64px;
  }
}
