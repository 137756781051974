.block--usps {
  border-top: 1px solid $color-light-accent;
  border-bottom: 1px solid $color-light-accent;

  .redactor {
    font-size: 30px;
    font-weight: bold;
    line-height: 110%;

    @include tablet {
      font-size: 42px;
    }
  }
}
